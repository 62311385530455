import { reactive } from 'vue';
import globals from './Globals';
import i18next from 'i18next';

const security = reactive({
    client_salt: "",
    personal_key: "",   //wird aus client_salt und PW_hash erzeugt, dient zum Entschl�sseln des DatenbankKeys
    encrypted_database_key: "",     //dient zum Ver- und Entschl�sseln der FileKeys
    database_key_iv: "",  //dient zum Entschl�sselnt des DatenbankKeys
    database_key_id: "",  //welcher DatenbankKey ist es
    current_username: '',
    current_role: '',
    password: '',
    current_share_key: '', //der Key, der aus dem ShareLink generiert wird, welches das SharePW enth�lt.
    encrypted_database_keys: [], //h�lt div. database_keys, wird vom Server geladen

    generate_pw() {
        let pw = crypto.getRandomValues(new Uint8Array(32));
        return btoa(String.fromCharCode.apply(null, pw));
    },
    generate_salt() {
        let salt = crypto.getRandomValues(new Uint8Array(32));
        return btoa(String.fromCharCode.apply(null, salt));
    },
    async generate_personal_key(password, salt) {
        try {
            if (salt) {
                salt = Uint8Array.from(atob(salt), c => c.charCodeAt(0));
            }
            const importedKey = await window.crypto.subtle.importKey(
                "raw",
                new TextEncoder().encode(password),
                { name: "PBKDF2" },
                false,
                ["deriveKey"]
            );
            const derivedKey = await window.crypto.subtle.deriveKey(
                {
                    name: "PBKDF2",
                    salt: salt,
                    iterations: 10000,
                    hash: "SHA-256",
                },
                importedKey,
                { name: "AES-GCM", length: 256 },
                true,
                ["encrypt", "decrypt"]
            );
            return derivedKey;
        } catch (error) {
            //globals.errors.push(i18next.t('errors.create_personal_key_failed'));
            throw error;
        }
    },
    async generate_encrypted_database_key_info(personal_key) {
        const dbKey = crypto.getRandomValues(new Uint8Array(32));
        return await this.process_encryption(dbKey, personal_key);

    },
        
    async hash_pw(input) {
        const textEncoder = new TextEncoder();
        const passwordBuffer = textEncoder.encode(input);
        const hashBuffer = await crypto.subtle.digest('SHA-512', passwordBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    },
    async hash_pw_with_salt(input, salt) {
        return await this.hash_pw(input + salt);
    },
    
    async process_encryption(input, cryptoKey, iv) {

        if (!iv)
            iv = crypto.getRandomValues(new Uint8Array(12));

        let inputBuffer = null;
        if (input instanceof CryptoKey) {
            inputBuffer = await window.crypto.subtle.exportKey('raw', input);
        } else if (input instanceof Uint8Array) {
            inputBuffer = input;
        } else if (typeof input === 'string') {
            inputBuffer = new TextEncoder().encode(input);
        } else {
            throw new Error("unknown type");
        }

        const encryptedOutputBuffer = await window.crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: iv,
            },
            cryptoKey,
            inputBuffer
        );
        return {
            encryptedOutput: btoa(String.fromCharCode(...new Uint8Array(encryptedOutputBuffer))),
            iv: btoa(String.fromCharCode(...iv)),
        };
    },
    decode_secure_share_token(secure_share_token) {
        const cleanedBase64 = secure_share_token.replace(/-/g, '');
        const decodedString = atob(cleanedBase64);
        const parts = decodedString.split('.');
        if (parts.length === 2) {
            const partBeforeDot = parts[0];
            const partAfterDot = parts[1];

            return {
                share_token: partBeforeDot,
                share_pw: partAfterDot
            };
        } else {
            throw new Error("errors.invalid_code");
        }
    },

    async process_decryption(input, cryptoKey, iv) {
        let inputBuffer;
        // Wenn input ein ArrayBuffer ist
        if (input instanceof ArrayBuffer) {

            inputBuffer = input;
        }
        // Wenn input ein Base64-String ist
        else if (typeof input === 'string' && /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/.test(input)) {

            inputBuffer = Uint8Array.from(atob(input), c => c.charCodeAt(0));
        }
        // Ansonsten Fehler werfen
        else {
            throw new Error("Invalid input. Input should be a Base64 encoded string or an ArrayBuffer.");
        }
        //console.log("input", input);
        //console.log("cryptoKey", cryptoKey);
        //console.log("iv", iv);

        return await window.crypto.subtle.decrypt(
            {
                name: "AES-GCM",
                iv: Uint8Array.from(atob(iv), c => c.charCodeAt(0)),
            },
            cryptoKey,
            inputBuffer
        );
    },
    async is_decription_possible(encryptedData, iv, key) {
        try {
            //console.log("encryptedData: ", encryptedData);
            //console.log("iv: ", iv);
            //console.log("key: ", key);

            await this.process_decryption(encryptedData, key, iv);
            return true; // Erfolgreiche Entschl�sselung
        } catch (error) {
            //console.log(error);
            //console.log(error.message);
            console.error("is_decription_possible failed", error);
            return false; // Entschl�sselung fehlgeschlagen
        }
    },
    async process_decryption_as_crypto_key(encrypted_key, key_iv, key) {
        try {
            const decryptedArrayBuffer = await this.process_decryption(encrypted_key, key, key_iv);
            // Importieren des entschl�sselten ArrayBuffers als CryptoKey
            const decryptedCryptoKey = await window.crypto.subtle.importKey(
                "raw",
                decryptedArrayBuffer,
                { name: "AES-GCM", length: 256 },
                true,
                ["encrypt", "decrypt"]
            );
            return decryptedCryptoKey;
        } catch (e) {
            console.error("decryption error", e)
        }
    },
    

    async process_encryptionFile(file, cryptoDbKey, share_token_encryption_infos) {
        // Neuen Schl�ssel und IV f�r die Datei generieren
        const fileKey = crypto.getRandomValues(new Uint8Array(32));
        const fileIV = crypto.getRandomValues(new Uint8Array(12));

        const cryptoFileKey = await window.crypto.subtle.importKey(
            "raw",               // Format
            fileKey,             // Rohschl�ssel
            { name: "AES-GCM" }, // Algorithmus
            false,               // Ob der Schl�ssel extrahiert werden kann
            ["encrypt", "decrypt"] // Erlaubte Aktionen
        );
        const fileInfos = new TextEncoder().encode(JSON.stringify({
            fileName: file.name,
            fileSize: file.size,
            fileContentType: file.type,
            fileRelativePath: file.relativePath,
            fileUploadId: file.uploadId,
        }));
        

        // FileInfos verschl�sseln
        const encryptedFileInfos = await this.process_encryption(fileInfos, cryptoFileKey, fileIV);

        // Dateiinhalt verschl�sseln
        const fileData = new Uint8Array(await file.arrayBuffer());
        const encryptedFileData = await window.crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: fileIV,
            },
            cryptoFileKey,
            fileData
        );

        // Dateischl�ssel mit dem decryptedKey verschl�sseln
        const encryptedFileKey = await this.process_encryption(fileKey, cryptoDbKey, fileIV);
        let share_token_file_encryption_infos = [];
        for (const share_token_encryption_info of share_token_encryption_infos) {
            const encryptedFileKeyInfos = await this.process_encryption(fileKey, share_token_encryption_info.dbKey, fileIV)

            share_token_file_encryption_infos.push({
                id: share_token_encryption_info.id,
                pw: share_token_encryption_info.pw,
                salt: share_token_encryption_info.salt,
                encrpyt_dbKey: share_token_encryption_info.encrpyt_dbKey,
                encrpyt_dbKey_iv: share_token_encryption_info.encrpyt_dbKey_iv,
                encrpyt_fileKey: encryptedFileKeyInfos.encryptedOutput,
                encrpyt_fileKey_iv: encryptedFileKeyInfos.iv,
            });
        }

        return {
            encryptedFileInfos: encryptedFileInfos.encryptedOutput,
            encryptedFileData: new Blob([new Uint8Array(encryptedFileData)]),
            encryptedFileKey: encryptedFileKey.encryptedOutput,
            fileIV: btoa(String.fromCharCode(...fileIV)),
            share_token_file_encryption_infos: share_token_file_encryption_infos,
        };
    },

    async process_decryption_file_info(encryptedFileData, cryptoKey) {

        const cryptoFileKey = await this.process_decryption_as_crypto_key(encryptedFileData.encrypted_file_key, encryptedFileData.file_key_iv, cryptoKey);
        const decryptedFileInfoBuffer = await this.process_decryption(encryptedFileData.encrypted_file_info, cryptoFileKey, encryptedFileData.file_key_iv);
        const decryptedFileInfo = JSON.parse(new TextDecoder().decode(decryptedFileInfoBuffer));
        return {
            name: decryptedFileInfo.fileName,
            size: decryptedFileInfo.fileSize,
            contenttype: decryptedFileInfo.fileContentType,
            relativePath: decryptedFileInfo.fileRelativePath,
            uploadId: decryptedFileInfo.fileUploadId,
        }
    },

    async process_decryption_file_blob(encryptedBlob, encryptedFileData, cryptoKey) {
        const cryptoFileKey = await this.process_decryption_as_crypto_key(encryptedFileData.encrypted_file_key, encryptedFileData.file_key_iv, cryptoKey);
        const decryptedFileBuffer = await this.process_decryption(await encryptedBlob.arrayBuffer(), cryptoFileKey, encryptedFileData.file_key_iv);
        const blob = new Blob([decryptedFileBuffer]);
        return blob;
    },

    async download_decrypted_key(encryptedKeys, filename, personalKey) {

        let exportEdks = [];
        for (let edk of encryptedKeys) {
            const dbkey = await this.process_decryption_as_crypto_key(edk.encrypted_database_key, edk.database_key_iv, personalKey);
            const exportedKeyBuffer = await window.crypto.subtle.exportKey("raw", dbkey);
            const keyBase64 = btoa(String.fromCharCode(...new Uint8Array(exportedKeyBuffer)));
            exportEdks.push({ key: keyBase64, id: edk.database_key_id });
        }
        const blob = new Blob([JSON.stringify(exportEdks)], { type: "application/json" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
    },

    async load_and_encrypt_Keys_from_File(file, personalKey) {
        // FileReader mit Promises verwenden
        const readFile = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = event => resolve(event.target.result);
                reader.onerror = error => reject(error);

                reader.readAsText(file);
            });
        };

        try {
            const fileContent = await readFile(file);
            const jsonData = JSON.parse(fileContent);
            /*console.log("jsonData:", jsonData);*/

            let importedKeys = [];

            for (let keyInfo of jsonData) {
                const keyBase64 = keyInfo.key;
                const keyBuffer = Uint8Array.from(atob(keyBase64), c => c.charCodeAt(0));

                // Den ArrayBuffer in einen CryptoKey umwandeln
                const importedKey = await window.crypto.subtle.importKey(
                    "raw",
                    keyBuffer,
                    { name: "AES-GCM" },
                    true,
                    ["encrypt", "decrypt"]
                );

                const edki = await this.process_encryption(importedKey, personalKey);
                importedKeys.push({ encrypted_database_key: edki.encryptedOutput, database_key_id: keyInfo.id, database_key_iv: edki.iv });
            }
            return importedKeys;
        } catch (error) {
            console.error("Fehler beim Laden und Verschl�sseln der Schl�ssel aus der Datei:", error);
            throw error;
        }
    },

    async process_post_login(login_payload) {
        var jwtDecode = require('jwt-decode');
        const decodedPayload = jwtDecode.jwtDecode(login_payload.jwt);
        const username = decodedPayload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
        const role = decodedPayload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        const expirationDate = new Date(decodedPayload.exp * 1000);
        localStorage.setItem("jwt", login_payload.jwt);
        localStorage.setItem("refreshToken", login_payload.refresh_token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("username", username);
        localStorage.setItem("role", role);

        this.current_username = localStorage.getItem("username");
        this.current_role = role;
        
        globals.loggedIn = true;

        this.twofa_code = '';
        this.access_token = '';
        this.username = '';

        try {

            //Client Salt erstellen, so noch keiner vorhanden ist
            this.client_salt = login_payload.client_salt;
            if (!this.client_salt) {
                this.client_salt = this.generate_salt();
                this.send_client_salt(this.client_salt);
            }
            //aus dem PW ein Hash machen
            if (this.password && this.client_salt) {
                let client_hash = await this.hash_pw_with_salt(this.password, this.client_salt);
                localStorage.setItem("client_hash", client_hash);
                this.password = '';
            }
           
            //wenn der Hash vorhanden ist, kann es weiter gehen
            if (localStorage.getItem("client_hash")) {
                let client_hash = localStorage.getItem("client_hash");
                this.personal_key = await this.generate_personal_key(client_hash, this.client_salt);
            } else {
                globals.errors.push(i18next.t('errors.login_failed'));
                await this.logout(true);
                return;
            }
            //wenn wir hier sind, k�nnen wir den empfangenen Datenbankschl�ssel entschl�sseln oder einen neuen bauen
            if (!login_payload.encrypted_database_key && !login_payload.database_key_iv && !login_payload.database_key_id) {   //es gibt noch keinen
                const edki = await this.generate_encrypted_database_key_info(this.personal_key)
                this.encrypted_database_key = edki.encryptedOutput;
                this.database_key_iv = edki.iv;
                this.database_key_id = ''; //weil neu
                this.send_client_secrets(this.encrypted_database_key,
                    this.database_key_iv);
            } else if (login_payload.encrypted_database_key && login_payload.database_key_iv && login_payload.database_key_id) {
                //alle Daten kamen von der API... 
                this.encrypted_database_key = login_payload.encrypted_database_key;
                this.database_key_iv = login_payload.database_key_iv;
                this.database_key_id = login_payload.database_key_id;


                //console.log("Login-this.encrypted_database_key", this.encrypted_database_key);

                const tryResult = await this.is_decription_possible(this.encrypted_database_key, this.database_key_iv, this.personal_key)
                if (tryResult) {
                   
                } else {
                    await this.logout(true);
                    globals.errors.push(i18next.t('errors.login_failed'));

                    return;
                }
            } else {   //es ist scheinbar nur ein Teil vorhanden, das darf und kann nicht sein... ein Fataler Fehler
                globals.errors.push(i18next.t('errors.login_failed'));
                await this.logout(true);
                return;
            }
        } catch (error) {
            console.error(error);
            globals.errors.push(i18next.t('errors.create_personal_key_failed'));
        }

        this.password = '';
        globals.hide_loading();
        //globals.hide_register();
        //this.show_login_panel = false;
        globals.is_login_dialog_visible = false;
        //this.show_2fa_code_dialog = 0;


        //if (globals.reload_after_login) {
        //    globals.reload_after_login = false;
        //    /*globals.checkUrl();*/
        //}
        
    },
    async generate_anonymous_key() {
        this.client_salt = this.generate_salt();
        let client_hash = await this.hash_pw_with_salt(
            this.generate_pw(), this.client_salt);
        localStorage.setItem("client_hash", client_hash);
        this.personal_key = await this.generate_personal_key(client_hash, this.client_salt);
        const edki = await this.generate_encrypted_database_key_info(this.personal_key)
        this.encrypted_database_key = edki.encryptedOutput;
        this.database_key_iv = edki.iv;
        this.database_key_id = ''; //weil neu
    },
    async send_client_salt(client_salt) {
        if (globals.loggedIn) {
            const url = "account/set-client-salt";
            const data = { "client_salt": client_salt };
            try {
                const response = await globals.make_Xhr_Request("PATCH", url, data);
                if (response.status !== 200) {
                    globals.show_error('errors.login_failed');
                    //globals.handle_Xhr_Errors(response.status, xhr.responseText);
                    await this.logout(true);
                }
            } catch (e) {
                throw e;
            }
            finally {
                //globals.hide_loading(); //hier noch nicht
            }
        } else {
            await this.logout();
        }
    },
    async send_client_secrets(encrypted_database_key, database_key_iv, database_key_id) {
        if (globals.loggedIn) {
            const url = "account/set-client-database-keys";
            const data = { encrypted_database_keys: [{ encrypted_database_key: encrypted_database_key, database_key_iv: database_key_iv, database_key_id: database_key_id }] };

            try {
                const response = await globals.make_Xhr_Request("PATCH", url, data);
                if (response.status !== 200) {
                    globals.show_error('errors.login_failed');
                    //globals.handle_Xhr_Errors(response.status, xhr.responseText);
                    await this.logout(true);
                } else {
                    await this.refresh_jwt();
                }

            } catch (e) {
                throw e;
            }
            finally {
                //globals.hide_loading(); //hier noch nicht
            }
        } else {
            await this.logout();
        }
    },
    async check_login_state() {
        //console.log('check_login_state');
        var token = localStorage.getItem("jwt");
        if (token) {
            var expirationDate = localStorage.getItem("expirationDate");
            var expirationDateObj = new Date(expirationDate);
            var currentDate = new Date();
            if (expirationDateObj < currentDate) { //JWT abgelaufen -> neues holen
                await this.refresh_jwt();
            }
            else {
                this.jwt = token;
                globals.loggedIn = true;
                this.current_username = localStorage.getItem("username");
            }
        }
        else {
            globals.loggedIn = false;
            await this.generate_anonymous_key();
        }
    },
    async refresh_jwt() {
        //console.log("refresh_jwt");

        var refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {

            const url = "authenticate/refresh-login"
            const data = { "refresh_token": localStorage.getItem("refreshToken"), "provider": globals.provider, "service": globals.service };

            try {
                const response = await globals.make_Xhr_Request("POST", url, data);

                if (response.status === 200) {

                    await this.process_post_login(JSON.parse(response.responseText).payload); // Token im Local Storage speichern

                } else {
                    globals.show_error('errors.login_failed');
                    //globals.handle_Xhr_Errors(response.status, xhr.responseText);
                    await this.logout();
                }
            } catch (error) {
                await this.logout();
                throw error;
            } finally {
                //globals.hide_loading(); //hier noch nicht
            }
        }
        else { //kein Refreshtoken da...
            if (globals.loggedIn) {
                await this.logout();
            }
        }
    },
    async logout(silent) {
        //console.log("logout!!!");
        if (globals.loggedIn) {

            const url = "authenticate/logout";
            try {
                const response = await globals.make_Xhr_Request("GET", url);

                if (response.status === 200) {
                    console.log("logout succeccfully");
                } else {
                    console.log("logout failed");
                }
            } catch (error) {
                console.log("logout failed", error);

            } finally {
                this.removeTokensInLocalstorage();
                if (!silent) {
                    window.location.reload();
                    //globals.goto_new_upload();
                }
                globals.hide_loading();
            }
        }
        else {
            this.removeTokensInLocalstorage();
            if (!silent) {
                window.location.reload();
                //globals.goto_new_upload()
            }
        }
    },
    removeTokensInLocalstorage() {
        localStorage.removeItem("jwt");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("username");
        localStorage.removeItem("role");
        localStorage.removeItem("client_hash");
        globals.loggedIn = false;
    },
    async supplement_download_content(fileShareInfos, share_pw) {
        this.id = fileShareInfos.id;
        this.message_text = fileShareInfos.message_text;
        this.subject_text = fileShareInfos.subject_text;
        this.from_address = fileShareInfos.from_address;
        this.to_address = fileShareInfos.to_address;
        this.expiry_date = fileShareInfos.expiry_date;
        this.created_on = fileShareInfos.created_on;
        const share_salt = fileShareInfos.share_salt;
        let processedFiles = [];
        let decryted_dbKeys = [];
        this.encrypted_database_keys = fileShareInfos.encrypted_database_keys;

        if (share_salt && share_pw) {

            this.current_share_key = await this.generate_personal_key(share_pw, share_salt);

        } else if (this.personal_key) {
            this.current_share_key = this.personal_key;
        } else {
            globals.show_error("errors.invalid_code");
        }
        for (const fileInfo of fileShareInfos.files) {
            if (fileInfo.is_encrypted) {
                try {
                    const cachedKeyInfo = decryted_dbKeys.find(d => d.id == fileInfo.database_key_id);
                    let dbKey = null;

                    if (!cachedKeyInfo) {
                        const encrypted_database_key_infos = this.encrypted_database_keys.find(d => d.database_key_id == fileInfo.database_key_id);
                        dbKey = await this.process_decryption_as_crypto_key(encrypted_database_key_infos.encrypted_database_key, encrypted_database_key_infos.database_key_iv, this.current_share_key);
                        decryted_dbKeys.push({ key: dbKey, id: fileInfo.database_key_id });
                    } else {
                        dbKey = cachedKeyInfo.key;
                    }
                    var decFileInfo = await this.process_decryption_file_info(fileInfo, dbKey);
                    fileInfo.name = decFileInfo.name;
                    fileInfo.size = decFileInfo.size;
                    fileInfo.contenttype = decFileInfo.contenttype;
                    fileInfo.type = decFileInfo.contenttype;
                    fileInfo.relativePath = decFileInfo.relativePath;
                    fileInfo.relativePathDirOnly = decFileInfo.relativePath.replace("/" + decFileInfo.name, "");
                    fileInfo.uploadId = decFileInfo.uploadId;
                    processedFiles.push(fileInfo);
                } catch (e) {
                    console.error(e);
                    fileInfo.name = "FEHLER";
                    fileInfo.size = 0;
                    fileInfo.contenttype = "FEHLER";
                    processedFiles.push(fileInfo);
                }
            } else {
                processedFiles.push(fileInfo);
            }
        }
        //processedFiles = processedFiles.sort((a, b) => {
        //    const comparison = a.filename.localeCompare(b.name);
        //    return comparison;
        //});
        return processedFiles;
    }

});

export default security;

