<template>
    <SiteHeader></SiteHeader>
    <div class="content-wrapper">
        <main class="site-container">
            <Login v-if="this.$route.name != 'Login'"></Login>
            <router-view></router-view>
        </main>
    </div>
    <SiteFooter></SiteFooter>
    <LoadingPanel></LoadingPanel>
    
    <ErrorDialog></ErrorDialog>
    <notifications v-for="(item, index) in globals.notifications"
                   :key="item.id"
                   :message="item.message"
                   :duration="item.duration"
                   :level="item.level"
                   :style="{ top: `${index * 40}px` }"
                   @after-leave="globals.remove_notification(item.id)">
    </notifications>

</template>

<script>
    import Notifications from '@common_components/Notification.vue';
    import SiteHeader from '@common_components/SiteHeader.vue';
    import SiteFooter from '@common_components/SiteFooter.vue';
    import LoadingPanel from '@common_components/LoadingPanel.vue';
    import ErrorDialog from '@common_components/ErrorDialog.vue';

    import Login from '@common_views/Login.vue';

    import helper from '@common_utils/Helper';
    import globals from '@common_utils/Globals';
    import security from '@common_utils/Security';

    

    export default {
        name: 'App',
        setup() {
            return {
                helper, security, globals
            };
        },
        components: {
            SiteHeader,
            SiteFooter,
            LoadingPanel,
            ErrorDialog,
            Notifications,
            Login,
        },
        
        data: () => ({

        }),
        methods: {
                        
        },
        beforeCreate: async function () {

            var lng = localStorage.getItem("i18nextLng");
            //console.log("beforeCreate: ", lng);
            if (!lng) {
                const browserLanguage = (navigator.language || navigator.userLanguage).substring(0, 2); // Erkennung der Browsersprache
                this.$i18next.changeLanguage(browserLanguage); // Sprache setzen
            }
        },
        mounted: async function () {
            let lng = localStorage.getItem("i18nextLng");
            //console.log("mounted: ", lng);
            if (lng) {
                this.globals.change_language(lng)
            } else {
                
                if (this.$i18next.resolvedLanguage) {
                    
                    this.globals.change_language(this.$i18next.resolvedLanguage)
                }
                else {

                }
            }
            await security.check_login_state();

            console.log("this.$route", this.$route);
            if (this.$route.params.goto) {
                console.log("kein Login!!!!!!")
            }
            
            console.log("globals.is_white_label_branding_modus", globals.is_white_label_branding_modus)
            

            //TODO: ForgotPW
            //TODO: showError eine Funktion f�r Schlie�en-Button mitgeben k�nnen, z.B. bei getDownloadContent auf goto_new_upload
            //TODO: init_upload ... Fehlerbehandlung ... zu i18 umwandeln

            
        },
        
    }
</script>


