<template>
    <div>
        <div class="site-title">Status von {{task_name}}</div>
        <div v-if="task_description" class="site-content">---{{task_description}}---</div>

        <div class="site-content" v-if="state_details.length>0">
            <StatusGrid :data="state_details"></StatusGrid>
        </div>
    </div>
</template>
<script>

    import helper from '@common_utils/Helper';
    import globals from '@common_utils/Globals';

    import StatusGrid from '../components/StatusGrid.vue';
    export default {
        name: 'Status',
        setup() {
            return {
                helper, globals
            };
        },
        components: {
            StatusGrid
        },
        props: {
            project_id: '',
            task_detail: '',
        },
        data: () => ({
            state_details: [],
            task_name: '',
            task_description: '',
            
            //confirm_token: '',
            //register_email: '',
            //register_password: '',
            //confirm_register_password: '',
            //confirmation_successfully: false,
            //register_successfully: false,
            //show_register_panel: false,
        }),
        methods: {
            async get_status() {

                
                const url = "state";
                
                const data = {
                    project_id: this.project_id,
                    task_id: this.task_detail.id,
                };
                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, data);
                    const result = JSON.parse(response.responseText);
                    //this.upload_token = result.payload.details;

                    let workingList = result.payload.details;

                    const sortDirection = 'ASC'

                    this.state_details = workingList.sort((a, b) => {
                        const comparison = new Date(a.measured_at) - new Date(b.measured_at);
                        return sortDirection === 'ASC' ? comparison : -comparison;
                    });


                    


                    if (response.status === 200) {
                        this.register_successfully = true;
                    } else if (response.status === 401) {
                        this.globals.show_error('errors.email_rejected');
                    } else if (response.status === 409) {
                        this.globals.show_error('errors.login_conflict');
                    } else if (response.status === 405 || response.status === 406 || response.status === 403) {
                        this.globals.show_error('errors.email_rejected');
                    } else if (response.status === 451) {
                        this.globals.show_error('errors.register_451');
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("register failed", e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async send_email_confirmation() {
                const url = "register/confirm-email?code=" + this.confirm_token;
                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);
                    if (response.status === 200) {
                        this.confirmation_successfully = true;

                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            close_success_register() {
                globals.goto_new_upload();
            },
            close_register_confirmation() {
                globals.goto_new_upload();
            },
            handleKeyDown(event) {
                //if (event.key === 'Escape') {
                //    if (this.confirmation_successfully)
                //        this.close_register_confirmation();
                //    else if (this.register_successfully)
                //        this.close_success_register();
                //}
            }, 
            show_me() {
                //globals.setPageTitel("status.headline");
                if (this.task_detail) {
                    this.task_name = this.task_detail.name;
                    this.task_description = this.task_detail.description;
                }
                this.get_status();

                //if (this.$route.name === 'Register_confirm_email' && this.token) {
                //    this.confirm_token = this.token;
                //    if (this.confirmation_successfully) {
                //        if (globals.loggedIn) {
                //            //dann raus aus register
                //            this.$router.push('/');
                //            return;
                //        }
                //    }
                //    this.send_email_confirmation();
                //} else {
                //    if (globals.loggedIn) {
                //        //dann raus aus register
                //        this.$router.push('/');
                //    }
                //}
                document.addEventListener('keydown', this.handleKeyDown);
            }
        },
        watch: {
        },
        mounted: function () {
            this.show_me();
            
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        },
    }
</script>