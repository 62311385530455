// src/directives/v-tippy.js
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

const TippyDirective = {
    mounted(el, binding) {
        tippy(el, {
            content: binding.value(),
            allowHTML: true,
            interactive: true,
            appendTo: document.body,
            theme: 'light',
        });
    },
  updated(el, binding) {
    el._tippy.setContent(binding.value);
  },
  unmounted(el) {
    if (el._tippy) {
      el._tippy.destroy();
    }
  }
};

export default TippyDirective;