import { reactive } from 'vue';
import i18next from 'i18next';
import router from 'vue-router';
let nextId = 1;
const globals = reactive({
    provider: 'c172dd93-d438-4855-87fe-4496d0ba5828',
    service: '4d1993ea-e60e-4bf9-9a98-565c8330f7bb',
    loggedIn: false,
    api_url: '',
    home_url: '',
    
    base_api_url() {

        if (!this.api_url || !this.home_url) {
            this.get_base_urls();
        }
        return this.api_url;
    },
    get_home_url() {

        if (!this.api_url || !this.home_url) {
            this.get_base_urls();
        }
        return this.home_url;
    },
    get_register_url() {
        return this.get_home_url() + "register";
    },
    get_more_features_url() {
        return "https://www.file.direct";
    },
    get_forgot_pw_url() {
        return this.get_home_url() + "forgot_pw";
    },
    get_base_urls() {
        console.log("window.location.host:", window.location.host);
        switch (window.location.host) {
            case 'file.direct':
                this.api_url = 'https://api-ap.file.direct/';
                this.home_url = 'https://file.direct/';
                break;
            case 'web.file.direct':
                this.api_url = 'https://api-ap.file.direct/';
                this.home_url = 'https://file.direct/';
                break;
            case 'app.file.direct':
                this.api_url = 'https://api-ap.file.direct/';
                this.home_url = 'https://file.direct/';
                break;
            case 'happy-moss-01584ea03.5.azurestaticapps.net':
                this.api_url = 'https://metimus-worker-api.azurewebsites.net/';
                this.home_url = 'https://happy-moss-01584ea03.5.azurestaticapps.net/';
                break;
            case 'localhost:1337':
                this.api_url = 'https://localhost:7223/';
                //this.api_url = 'https://metimus-worker-api.azurewebsites.net/';
                this.home_url = 'http://localhost:1337/';
                break;
            default:
                this.api_url = 'https://metimus-worker-api.azurewebsites.net/';
                this.home_url = 'https://' + window.location.host + '/';
        }
    },

    
    goto_new_upload() {
        window.location.href = this.get_home_url();
    },

    goto_register(register_token, register_with_email) {
        if (register_with_email && register_token) {
            window.location.href = this.get_home_url() + "register/" + register_token + "/" + register_with_email;
        } else {
            window.location.href = this.get_home_url() + "register";
        }
    },
    is_login_dialog_visible: false,

    abortable_xhr: null,
    abort_Xhr_Request() {
        //console.log("abort_Xhr_Request", this.abortable_xhr);
        if (this.abortable_xhr) {
            this.abortable_xhr.abort();
        }
    },
    make_Xhr_AbortableRequest(method, url, data, responseType) {
        //this.errors = [];
        this.show_loading();

        return new Promise((resolve, reject) => {

            var abortable_xhr = new XMLHttpRequest();
            if (responseType) {
                abortable_xhr.responseType = responseType;
            }
            this.abortable_xhr = abortable_xhr;

            // �berpr�fen, ob die URL relativ ist und gegebenenfalls die Basis-URL hinzuf�gen
            //console.log("url: ", url);
            const fullUrl = url.startsWith('http://') || url.startsWith('https://') ? url : this.base_api_url() + url;
            //console.log("fullUrl: ", fullUrl);


            abortable_xhr.open(method, fullUrl, true);
            //this.abortable_xhr.setRequestHeader("Content-Type", "application/json");
            abortable_xhr.setRequestHeader("Accept-Language", this.currentLanguage);

            if (this.loggedIn) {
                var jwt = localStorage.getItem("jwt");
                abortable_xhr.setRequestHeader("Authorization", "Bearer " + jwt);
            };

            abortable_xhr.upload.addEventListener('progress', (event) => {
                //console.log("event.lengthComputable", event.lengthComputable);
                //console.log("event.loaded", event.loaded);
                //console.log("event.total", event.total);
                //console.log("event", event);
                if (event.lengthComputable) {
                    globals.loading_progress = Math.floor((event.loaded / event.total) * 100);
                }
            }, false);

            abortable_xhr.onprogress = function (event) {
                //console.log("onprogress", event);

                if (event.lengthComputable) {
                    var progress = Math.floor((event.loaded / event.total) * 100);
                    // Hier haben Sie den Fortschrittsprozentsatz (progress), den Sie wie gew�nscht verwenden k�nnen
                    globals.loading_progress = progress;
                }
            };

            abortable_xhr.onload = function () {
                //console.log("abortable_xhr", abortable_xhr);
                resolve(abortable_xhr);
            };
            abortable_xhr.onabort = function () {
                globals.hide_loading();
            };

            abortable_xhr.onerror = function () {
                globals.hide_loading();
                globals.show_error('errors.network_error');
                reject(new Error("Network Error"));
            };

            if (data) {
                //console.log("###1");
                abortable_xhr.send(data);
                //this.abortable_xhr.send(JSON.stringify(data));
            } else {
                //console.log("###2");
                abortable_xhr.send();
            }
        });
    },
    make_Xhr_Request(method, url, data) {

        //this.errors = [];
        this.show_loading();

        return new Promise((resolve, reject) => {

            var xhr = new XMLHttpRequest();

            // �berpr�fen, ob die URL relativ ist und gegebenenfalls die Basis-URL hinzuf�gen
            //console.log("url: ", url);
            const fullUrl = url.startsWith('http://') || url.startsWith('https://') ? url : this.base_api_url() + url;
            //console.log("fullUrl: ", fullUrl);


            xhr.open(method, fullUrl, true);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("Accept-Language", this.currentLanguage);

            if (this.loggedIn) {
                var jwt = localStorage.getItem("jwt");
                xhr.setRequestHeader("Authorization", "Bearer " + jwt);
            }

            xhr.onload = function () {
                //console.log("xhr", xhr);
                resolve(xhr);
            };
            xhr.onerror = function () {
                globals.hide_loading();
                globals.show_error('errors.network_error');
                reject(new Error("Network Error"));
            };

            if (data) {
                xhr.send(JSON.stringify(data));
            } else {
                xhr.send();
            }
        });
    },
    handle_Xhr_Errors(status, responseText) {
        let error_string = '';
        if (status === 400) {
            error_string = 'errors.incomplete_data';
        } else if (status === 401) {
            error_string = 'errors.access_denied';
        } else if (status === 403) {
            error_string = 'errors.invalid_code';
        } else if (status === 408) {
            error_string = 'errors.files_expired';
        } else if (status === 404) {
            error_string = 'errors.unknown_datarecord';
        } else if (status === 413) {
            error_string = 'errors.error_413_to_large';
        } else {
            error_string = 'errors.internal';
        }
        this.show_error(error_string);
        console.error("handle_Xhr_Errors: " + responseText + " (" + status + ")");
    },

    
    ////////////////////////////////////////////////////////////////////////////////////
    errors: [],    
    notifications: [],
    show_error(i18_string, exOb) {
        this.hide_loading();    //wenn Fehlermeldung, dann kein Spinner mehr
        this.errors.push(i18next.t(i18_string, exOb));
    },
    trigger_notification(notificationText, notificationLevel) {
        this.notifications.push({
            id: nextId++,
            message: notificationText,
            level: notificationLevel,
            duration: 3000
        });
    },
    remove_notification(id) {
        this.notifications = this.notifications.filter(item => item.id !== id);
    },

    ////////////////////////////////////////////////////////////////////////////////////
    is_loading_panel_visible: false,
    loading_progress: 0,
    hide_loading() {
        this.is_loading_panel_visible = false;
        this.loading_progress = 0;
        this.abortable_xhr = null;
    },
    show_loading() {
        this.is_loading_panel_visible = true;
    },


    ////////////////////////////////////////////////////////////////////////////////////

    selected_language: 'de',
    available_languages: {
        de: { nativeName: 'Deutsch', internalName: 'german' },
        en: { nativeName: 'English', internalName: 'english' },
    },
    change_language(lng) {
        //console.log("change_language: ", lng);
        if (lng) {
            this.selected_language = lng
            //console.log("this.selected_language: ", this.selected_language);
            i18next.changeLanguage(this.selected_language);
            this.setPageTitel();
        }
    },
    get_current_language() {
        return i18next.resolvedLanguage + "";
    },
    current_pagetitle: '',
    setPageTitel(i18n_pageTitel) {
        if (i18n_pageTitel)
            this.current_pagetitle = i18n_pageTitel;

        if (this.current_pagetitle) {
            let title = '';
            try {
                title = i18next.t(this.current_pagetitle);
            } catch (e) {
                title = this.current_pagetitle;
            }
            document.title =  title + " - " + i18next.t("general.app_title");
        } else {
            document.title = i18next.t("general.app_title");
        }
    },

    is_white_label_branding_modus: false,
    current_icon_image_url: '',
    current_icon_link_url: '',
    current_footer_description: '',
    current_footer_navbar: '',


    ////////////////////////////////////////////////////////////////////////////////////
    selected_files: [],
    selected_folderIds: [],

    ////////////////////////////////////////////////////////////////////////////////////

    async download_blob(file_id, file_token) {

        //const url = "files/generate-url";
        //const data = { id: file_id, token: file_token, };
        //const response = await this.make_Xhr_Request("POST", url, data);
        //if (response.status === 200) {
        //    var result = JSON.parse(response.responseText);
        //    this.abortable_xhr = new XMLHttpRequest();
        //    this.abortable_xhr.open("GET", result.payload.url, true);
        //    this.abortable_xhr.responseType = "blob";

        //    this.abortable_xhr.onprogress = function (event) {
        //        if (event.lengthComputable) {
        //            var progress = Math.floor((event.loaded / event.total) * 100);
        //            // Hier haben Sie den Fortschrittsprozentsatz (progress), den Sie wie gew�nscht verwenden k�nnen
        //            this.loading_progress = progress;
        //        }
        //    };

        //    this.abortable_xhr.onload = async function () {
        //        if (this.status === 200) {
        //            var blob = this.response;
        //            var fileInfo = thisComponent.files.find(d => d.id == id);
        //            if (fileInfo.is_encrypted) {
        //                var edbk = thisComponent.encrypted_database_keys.find(d => d.database_key_id == fileInfo.database_key_id);
        //                const dbkey = await security.process_decryption_as_crypto_key(edbk.encrypted_database_key, edbk.database_key_iv, thisComponent.share_key)
        //                blob = await security.process_decryption_file_blob(blob, fileInfo, dbkey)
        //            }
        //            return blob;
        //        }
        //    };

        //    globals.abortable_xhr.onabort = function () {
        //        globals.hide_loading();
        //    };
        //    globals.abortable_xhr.onerror = function () {
        //        globals.show_error('errors.download_failed');
        //        globals.hide_loading();
        //    };

        //    globals.abortable_xhr.send();

        //} else if (response.status === 408) {
        //    globals.show_error('errors.files_expired');
        //} else if (response.status === 400) {
        //    globals.show_error('errors.invalid_link');
        //} else if (response.status === 404) {
        //    globals.show_error('errors.files_not_found');
        //} else {
        //    this.globals.handle_Xhr_Errors(response.status, response.responseText);
        //}
    }
    
});

export default globals;
