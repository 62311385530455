<template>
    <MarketingPanel></MarketingPanel>
    <div v-if="!this.confirmation_successfully && !this.register_successfully">
        <div id="register_form_panel">
            <div class="site-title">{{ $t('register.headline')}}</div>
            <div class="site-content" v-html="$t('register.infotext')"></div>

            <form v-on:submit.prevent="send_register" class="bodyform">
                <div class="bodyform__item">
                    <label for="email">{{ $t('register.form.labels.email')}}</label>
                    <input id="email" name="email" type="email" v-model="register_email" autocomplete="off" />
                </div>
                <div class="bodyform__item">
                    <label for="register-password">{{ $t('register.form.labels.password')}}</label>
                    <input id="register-password" name="register-password" type="password" v-model="register_password" @input="this.$refs.checkpassword_component.checkPasswordStrength(this.register_password)" autocomplete="new-password" />
                </div>
                <div class="bodyform__item">
                    <CheckPassword ref="checkpassword_component"></CheckPassword>
                </div>
                <div class="bodyform__item">
                    <label for="confirm_register-password">{{ $t('register.form.labels.password_confirm')}}</label>
                    <input id="confirm_register-password" name="confirm_register-password" type="password" v-model="confirm_register_password" autocomplete="new-password" />
                </div>
                <div class="bodyform__item">
                    <button class="button" type="submit">{{ $t('register.form.labels.submit')}}</button>
                </div>
            </form>

        </div>
    </div>
    <div v-if="register_successfully">
        <div class="site-title">{{ $t('register.headline') }}</div>
        <div class="site-content">
            <div class="highlighted">
                {{$t('register.register_success_dialog.headline')}}
            </div>
        </div>
        <!--<div id="register_success-dialog" class="dialog-panel success">
            <div style="font-size:16px; font-weight:700; padding-bottom:10px;">{{ }}</div>
            <div style="padding-top: 20px; text-align: center;"><button type="button" class="button error" @click="close_success_register();">{{ $t('register.register_success_dialog.close_button')}}</button></div>
        </div>-->
    </div>
    <div v-if="confirmation_successfully">
        <div class="site-title">{{ $t('register.headline') }}</div>
        <div class="site-content">
            <div class="highlighted">
                {{$t('register.confirmation_success_dialog.headline')}}
            </div>
        </div>
        <!--<div id="confirmation_success-dialog" class="dialog-panel success">
            <div style="font-size:16px; font-weight:700; padding-bottom:10px;">{{ $t('register.confirmation_success_dialog.headline')}}</div>
            <div style="padding-top: 20px; text-align: center;"><button type="button" class="button error" @click="close_register_confirmation();">{{ $t('register.confirmation_success_dialog.close_button')}}</button></div>
        </div>-->
    </div>
</template>
<script>
    import CheckPassword from '../components/CheckPassword.vue';
    import MarketingPanel from '../components/MarketingPanel.vue';

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';

    export default {
        name: 'Register',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            CheckPassword,
            MarketingPanel
        },
        props: {
            token: '',
            register_token: '',
            register_with_email: '',
        },
        data: () => ({
            confirm_token: '',
            register_email: '',
            register_password: '',
            confirm_register_password: '',
            confirmation_successfully: false,
            register_successfully: false,
            show_register_panel: false,
        }),
        methods: {
            async send_register() {

                if ((this.register_password + "") !== (this.confirm_register_password + "")) {
                    this.globals.show_error('errors.password_not_match');
                    return;
                }
                if (!this.$refs.checkpassword_component.checkPasswordStrength(this.register_password)) {
                    this.globals.show_error('errors.password_requirements_failed');
                    return;
                }

                const url = "register";
                const pw_hash = await security.hash_pw(this.register_password);
                const data = {
                    email: this.register_email,
                    password: pw_hash,
                    language: globals.selected_language,
                    register_token: this.register_token,
                    provider: globals.provider,
                    service: globals.service,
                };
                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, data);
                    if (response.status === 200) {
                        this.register_successfully = true;
                    } else if (response.status === 401) {
                        this.globals.show_error('errors.email_rejected');
                    } else if (response.status === 409) {
                        this.globals.show_error('errors.login_conflict');
                    } else if (response.status === 405 || response.status === 406 || response.status === 403) {
                        this.globals.show_error('errors.email_rejected');
                    } else if (response.status === 451) {
                        this.globals.show_error('errors.register_451');
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("register failed", e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async send_email_confirmation() {
                const url = "register/confirm-email?code=" + this.confirm_token;
                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);
                    if (response.status === 200) {
                        this.confirmation_successfully = true;

                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            close_success_register() {
                globals.goto_new_upload();
            },
            close_register_confirmation() {
                globals.goto_new_upload();
            },
            handleKeyDown(event) {
                //if (event.key === 'Escape') {
                //    if (this.confirmation_successfully)
                //        this.close_register_confirmation();
                //    else if (this.register_successfully)
                //        this.close_success_register();
                //}
            }, 
            show_me() {
                globals.setPageTitel("register.headline");
                if (this.$route.name === 'Register_confirm_email' && this.token) {
                    this.confirm_token = this.token;
                    if (this.confirmation_successfully) {
                        if (globals.loggedIn) {
                            //dann raus aus register
                            this.$router.push('/');
                            return;
                        }
                    }
                    this.send_email_confirmation();
                } else {
                    if (globals.loggedIn) {
                        //dann raus aus register
                        this.$router.push('/');
                    }
                }
                document.addEventListener('keydown', this.handleKeyDown);
            }
        },
        watch: {
            'globals.loggedIn'(newVal, oldVal) {
                this.show_me();    
            }
        },
        mounted: function () {
            this.register_successfully = false;
            this.confirmation_successfully = false;
            this.show_me();
            
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        },
    }
</script>