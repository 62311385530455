<template>
    <div class="modal" v-show="this.globals.is_loading_panel_visible">
        <div id="loading" v-if="this.globals.loading_progress<=0">
            <i class="fa-duotone fa-loader fa-10x fa-spin-pulse" style="--fa-primary-color: #2f7fc1; --fa-secondary-color: #6eb5e5;"></i>
            <div class="abort-button" v-if="this.globals.abortable_xhr" @click="abort_Xhr_Request()"><a>{{ $t('loading.cancel_button')}}</a></div>
        </div>

        <div id="loading" class="donut-chart" v-if="this.globals.loading_progress>0">
            <div class="circle" :style="circleStyle"></div>
            <div class="circle-overlay"></div>
            <div class="circle-percentage">{{ this.globals.loading_progress }}%</div>
            <div class="abort-button" v-if="this.globals.abortable_xhr" @click="abort_Xhr_Request()"><a>{{ $t('loading.cancel_button')}}</a></div>
        </div>
    </div>    
</template>
<script>
    

    import globals from '../utils/Globals';
    export default {
        name: 'LoadingPanel',
        setup() {
            return {
                globals
            };
        },
        computed: {
            circleStyle() {
                return {
                    '--percentage': `${globals.loading_progress}%`
                };
            },
        },
        props: {
            
        },
        data: () => ({
            
        }),
        methods: {
            abort_Xhr_Request() {
                //console.log("#######################################");
                //console.log("globals", globals);
                //console.log("this.globals.abortable_xhr", this.globals.abortable_xhr);

                globals.abort_Xhr_Request();
            }

        },
        mounted: function () {
        },
    }
</script>