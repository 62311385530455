<template>
    <div>
        <div class="site-title">{{project_name}}</div>

        <div v-for="(task_detail, index) in task_details">
            <StatusTask :project_id="project_id" :task_detail="task_detail"></StatusTask>
        </div>
    </div>
</template>
<script>

    import helper from '@common_utils/Helper';
    import globals from '@common_utils/Globals';

    import StatusTask from '../components/StatusTask.vue';
    export default {
        name: 'Status',
        setup() {
            return {
                helper, globals
            };
        },
        components: {
            StatusTask
        },
        props: {
            //project_id: "d73d98d1-cf3c-4ed3-a083-d57407bc8d7e",
            project_id: '',
            task_ids: '',
            
        },
        data: () => ({
            //task_ids: ["a8d62a08-c20c-410e-817a-d55fe8d0a1b9","06a6ef9e-024c-42ee-82f1-5042fde53fa8"],
            //task_ids: [],
            task_details: [],
            project_name: '',
        }),
        methods: {
            async get_project() {

                
                const url = "state/project";
                
                const data = {
                    project_id: this.project_id
                };
                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, data);
                    var result = JSON.parse(response.responseText);
                    //this.upload_token = result.payload.details;
                    console.log("result.payload", result.payload);
                    this.project_name = result.payload.name;

                    globals.current_icon_image_url = result.payload.icon_image_url;
                    globals.current_icon_link_url = result.payload.icon_link_url;
                    console.log("globals.current_icon_image_url", globals.current_icon_image_url);

                    if (!this.task_ids && this.task_ids.length <1) {
                        this.task_details = result.payload.task_details;
                    } else {
                        let idsSet = new Set();
                        console.log("idsSet #1", idsSet);
                        
                        this.task_ids.forEach(d => { idsSet.add(d); });
                        console.log("idsSet #2", idsSet);
                        this.task_details = result.payload.task_details.filter(d => idsSet.has(d.id));
                    }
                    //console.log("######################",this.task_ids);
                    //this.state_details = result.payload.details;
                    //this.state_details.forEach(state_detail => {
                    //    if (state_detail.http_status_code == 200) {

                    //    }
                    //});


                    if (response.status === 200) {
                        this.register_successfully = true;
                    } else if (response.status === 401) {
                        this.globals.show_error('errors.email_rejected');
                    } else if (response.status === 409) {
                        this.globals.show_error('errors.login_conflict');
                    } else if (response.status === 405 || response.status === 406 || response.status === 403) {
                        this.globals.show_error('errors.email_rejected');
                    } else if (response.status === 451) {
                        this.globals.show_error('errors.register_451');
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("register failed", e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async send_email_confirmation() {
                const url = "register/confirm-email?code=" + this.confirm_token;
                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);
                    if (response.status === 200) {
                        this.confirmation_successfully = true;

                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            close_success_register() {
                globals.goto_new_upload();
            },
            close_register_confirmation() {
                globals.goto_new_upload();
            },
            handleKeyDown(event) {
                //if (event.key === 'Escape') {
                //    if (this.confirmation_successfully)
                //        this.close_register_confirmation();
                //    else if (this.register_successfully)
                //        this.close_success_register();
                //}
            }, 
            async show_me() {
                await this.get_project();
                globals.setPageTitel("status.headline");
                //this.project_id = "d73d98d1-cf3c-4ed3-a083-d57407bc8d7e";
                console.log("project_id", this.project_id);
                console.log("task_id",this.task_id);

                //this.task_ids = ["a8d62a08-c20c-410e-817a-d55fe8d0a1b9", "06a6ef9e-024c-42ee-82f1-5042fde53fa8"];
                //this.task_ids = [this.task_id];
                //this.get_status();

                //if (this.$route.name === 'Register_confirm_email' && this.token) {
                //    this.confirm_token = this.token;
                //    if (this.confirmation_successfully) {
                //        if (globals.loggedIn) {
                //            //dann raus aus register
                //            this.$router.push('/');
                //            return;
                //        }
                //    }
                //    this.send_email_confirmation();
                //} else {
                //    if (globals.loggedIn) {
                //        //dann raus aus register
                //        this.$router.push('/');
                //    }
                //}
                document.addEventListener('keydown', this.handleKeyDown);
            }
        },
        watch: {
        },
        mounted: async function () {
            await this.show_me();
            
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        },
    }
</script>