<template>
    <div v-if="goto==='status'">
        <Status :project_id="project_id" :task_ids="task_ids"></Status>
    </div>
    <div v-else>
       Hello
    </div>
</template>
<script>
    import Status from './Status.vue';

    import helper from '@common_utils/Helper';
    import globals from '@common_utils/Globals';
    export default {
        name: 'Home',
        setup() {
            return {
                helper, globals
            };
        },
        components: {
            Status,
        },
        props: {
            token: '',
            register_token: '',
            register_with_email: '',
            project_id: '',
            task_ids: '',
            goto: '',
        },
        data: () => ({
            confirm_token: '',
            register_email: '',
            register_password: '',
            confirm_register_password: '',
            confirmation_successfully: false,
            register_successfully: false,
            show_register_panel: false,
        }),
        methods: {
            async send_register() {

                if ((this.register_password + "") !== (this.confirm_register_password + "")) {
                    this.globals.show_error('errors.password_not_match');
                    return;
                }
                if (!this.$refs.checkpassword_component.checkPasswordStrength(this.register_password)) {
                    this.globals.show_error('errors.password_requirements_failed');
                    return;
                }

                const url = "register";
                const pw_hash = await security.hash_pw(this.register_password);
                const data = {
                    email: this.register_email,
                    password: pw_hash,
                    language: globals.selected_language,
                    register_token: this.register_token,
                    provider: globals.provider,
                    service: globals.service,
                };
                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, data);
                    if (response.status === 200) {
                        this.register_successfully = true;
                    } else if (response.status === 401) {
                        this.globals.show_error('errors.email_rejected');
                    } else if (response.status === 409) {
                        this.globals.show_error('errors.login_conflict');
                    } else if (response.status === 405 || response.status === 406 || response.status === 403) {
                        this.globals.show_error('errors.email_rejected');
                    } else if (response.status === 451) {
                        this.globals.show_error('errors.register_451');
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("register failed", e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async send_email_confirmation() {
                const url = "register/confirm-email?code=" + this.confirm_token;
                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);
                    if (response.status === 200) {
                        this.confirmation_successfully = true;

                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            close_success_register() {
                globals.goto_new_upload();
            },
            close_register_confirmation() {
                globals.goto_new_upload();
            },
            handleKeyDown(event) {
                //if (event.key === 'Escape') {
                //    if (this.confirmation_successfully)
                //        this.close_register_confirmation();
                //    else if (this.register_successfully)
                //        this.close_success_register();
                //}
            }, 
            show_me() {
                globals.setPageTitel("home.headline");
                //if (this.$route.name === 'Register_confirm_email' && this.token) {
                //    this.confirm_token = this.token;
                //    if (this.confirmation_successfully) {
                //        if (globals.loggedIn) {
                //            //dann raus aus register
                //            this.$router.push('/');
                //            return;
                //        }
                //    }
                //    this.send_email_confirmation();
                //} else {
                //    if (globals.loggedIn) {
                //        //dann raus aus register
                //        this.$router.push('/');
                //    }
                //}
                document.addEventListener('keydown', this.handleKeyDown);
            }
        },
        watch: {
        },
        mounted: function () {
            this.show_me();
            
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        },
    }
</script>