<template>
    <div id="marketing-div">
        <div class="marketing-tr">
            <div class="marketing-img"><img alt="german server" src="../assets/images/flag_german.png" /></div><div class="marketing-td">{{ $t('marketing.german_server')}}</div>
        </div>
        <div class="marketing-tr">
            <div class="marketing-img"><img alt="europe dsgvo" src="../assets/images/flag_europa.png" /></div><div class="marketing-td">{{ $t('marketing.europe_dsgvo')}}</div>
        </div>
        <div class="marketing-tr">
            <div class="marketing-img"><img alt="lock encryption" src="../assets/images/lock.png" /></div><div class="marketing-td">{{ $t('marketing.lock_encryption')}}</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'MarketingPanel',
        setup() {
        },
        props: {
            
        },
        data: () => ({
            
        }),
        methods: {

        },
        mounted: function () {
        },
    }
</script>