<template>
    <div id="profile" v-if="show_main">
        <div class="site-title">{{ $t('profile.headline')}}</div>
        <div class="site-content">
            <div class="subtitle">{{ $t('profile.personal_data.headline') }}</div>
            <div class="content label-value">
                <div class="label-value__label">
                    {{$t('profile.personal_data.labels.email')}}
                </div>
                <div class="label-value__value">
                    {{ this.user_email }}
                </div>
            </div>

            <div class="content right">
                <button type="button" @click="show_change_email_init();" class="button small wide toolbar">{{$t('profile.personal_data.actions.change_email.button')}}</button>
            </div>
            <div class="content right">
                <button type="button" @click="show_change_password();" class="button small wide toolbar">{{$t('profile.change_password.actions.change_password.button')}}</button>
            </div>
        </div>
        <div class="site-content">
            <div class="subtitle">{{ $t('profile.two_factor_authentication.headline') }}</div>
            <div v-if="!this.two_factor_enabled">
                <div class="content">{{ $t('profile.two_factor_authentication.enable_2fa.infotext') }}</div>
                <form v-on:submit.prevent="enable_mfa_authentication" class="bodyform">
                    <div class="content">
                        <label for="authentication-selection">{{ $t('profile.two_factor_authentication.enable_2fa.form.labels.method_selection') }}:</label>
                        <CustomDropdown :optionsList="two_factor_authentication_methods" :defaultLabel="'profile.two_factor_authentication.enable_2fa.form.labels.selection_placeholder'" @option-selected="handleOptionSelected"></CustomDropdown>
                    </div>
                    <div class="content" v-if="show_selection_authentication_method_email">{{$t('profile.two_factor_authentication.enable_2fa.form.options.email_detail')}}</div>
                    <div class="content" v-if="show_selection_authentication_method_auth_app">{{$t('profile.two_factor_authentication.enable_2fa.form.options.appauth_detail')}}</div>
                    <div class="content right">
                        <button :disabled="!two_factor_token_provider" type="submit" class="button small wide toolbar">{{$t('profile.two_factor_authentication.enable_2fa.form.buttons.activation')}}</button>
                    </div>
                </form>
            </div>
            <div v-else>
                <div class="content" v-if="this.two_factor_token_provider=='EMail'">{{$t('profile.two_factor_authentication.disable_2fa.infotexts.email_code')}}</div>
                <div class="content" v-else>{{$t('profile.two_factor_authentication.disable_2fa.infotexts.auth_app' )}}</div>
                <div class="content right">
                    <button type="button" @click="show_confirmation_2fa_disable()" class="button small wide toolbar">{{$t('profile.two_factor_authentication.disable_2fa.action.button')}}</button>
                </div>

            </div>
        </div>
        <div v-if="this.two_factor_enabled && this.two_factor_token_provider!='EMail'" class="site-content">
            <div class="subtitle">{{ $t('profile.two_factor_authentication.recovery_codes.headline') }}</div>
            <div class="content">{{ $t('profile.two_factor_authentication.recovery_codes.infotext') }}</div>
            <div class="content" v-if="this.recovery_codes_count>0">{{$t('profile.two_factor_authentication.recovery_codes.action.label')}}: {{ this.recovery_codes_count }} </div>
            <div class="content right">
                <button type="button" @click="generate_recovery_codes();" class="button small wide toolbar">{{$t('profile.two_factor_authentication.recovery_codes.action.button')}}</button>
            </div>
        </div>
        <div class="site-content">
            <div>
                <div class="subtitle">{{ $t('profile.personal_key.headline')}}</div>
                <div class="content" v-html="$t('profile.personal_key.infotext')"></div>
                <div v-if="!last_key_backup_date">
                    <div class="content">
                        <div style="font-weight:600">{{ $t('profile.personal_key.actions.never_saved.intro') }}</div>
                    </div>
                    <div class="content right">
                        <button type="button" @click="download_dbKey()" class="button small wide toolbar">{{$t('profile.personal_key.actions.never_saved.button')}}</button>
                    </div>
                </div>
                <div v-else>
                    <div class="content">
                        <div>{{ $t('profile.personal_key.actions.last_saved.intro') }}: {{ helper.formatReadableDate(last_key_backup_date, globals.selected_language) }}</div>
                    </div>
                    <div class="content right">
                        <button type="button" @click="download_dbKey()" class="button small wide toolbar">{{$t('profile.personal_key.actions.never_saved.button')}}</button>
                    </div>
                    <div class="content right">
                        <button type="button" @click="show_uploaded_keys_file();" class="button small wide toolbar">{{$t('profile.personal_key.actions.import_key.button')}}</button>

                    </div>
                </div>
            </div>
        </div>


        <div class="site-content">
            <div class="subtitle">{{ $t('profile.linked_devices.headline') }}</div>
            <div class="content">{{ $t('profile.linked_devices.infotext') }}</div>

            <div class="component-view-list">
                <div class="component-view-list-body">
                    <div v-for="linked_device in this.linked_devices" :key="linked_device.id" class="history-item">
                        <div class="component-view-list-body__item framed">
                            <div class="component-view-list-body__item-content">
                                <div class="component-view-list-body__row" v-if="own_device_id==linked_device.id">
                                    <div class="component-view-list-body__label"></div>
                                    <div class="component-view-list-body__content">{{$t('profile.linked_devices.listview.listitems.labels.current_session')}}</div>
                                </div>
                                <div class="component-view-list-body__row" v-if="linked_device.name">
                                    <div class="component-view-list-body__label">{{$t('profile.linked_devices.listview.listitems.labels.name')}}</div>
                                    <div class="component-view-list-body__content">{{ linked_device.name }}</div>
                                </div>
                                <div class="component-view-list-body__row" v-if="linked_device.last_action_on">
                                    <div class="component-view-list-body__label">{{$t('profile.linked_devices.listview.listitems.labels.last_action')}}</div>
                                    <div class="component-view-list-body__content">{{ helper.formatReadableDate(linked_device.last_action_on, globals.selected_language) }}</div>
                                </div>
                                <div class="component-view-list-body__row">
                                    <div class="component-view-list-body__label">{{$t('profile.linked_devices.listview.listitems.labels.details')}}</div>
                                    <div class="component-view-list-body__content">{{ [linked_device.ip, linked_device.device, linked_device.operating_system, linked_device.browser].filter(str => str && str.trim() !== "").join(", ")  }}</div>
                                </div>

                            </div>
                            <div class="component-view-list-body__toolbar">
                                <div :title="$t('profile.linked_devices.listview.listitems.buttons.set_name_tooltip')"><a :data-id="linked_device.id" @click="show_change_device_name($event);"><i class="fa-solid fa-tag"></i></a></div>
                                <div v-if="own_device_id!=linked_device.id" :title="$t('profile.linked_devices.listview.listitems.buttons.logout_device')"><a @click="revoke_linked_device($event);" :data-id="linked_device.id"><i class="fa-solid fa-right-from-bracket"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="dialogs">
        <div id="success_2fa_enabled_authenticator" v-if="show_success_2fa_enabled_authenticator_dialog > 0">
            <div v-if="show_success_2fa_enabled_authenticator_dialog == 1" class="site-title">{{ $t('profile.two_factor_authentication.enable_2fa.success_dialog_auth_app.headline')}}</div>
            <div v-if="show_success_2fa_enabled_authenticator_dialog == 2" class="site-title">{{ $t('profile.two_factor_authentication.enable_2fa.success_dialog_regeneration_recovery_codes.headline')}}</div>
            <div class="site-content" v-if="show_success_2fa_enabled_authenticator_dialog == 1">
                <div class="content">{{$t('profile.two_factor_authentication.enable_2fa.success_dialog_auth_app.infotext')}}</div>
                <div class="content">
                    <a :href="authenticator_url">
                        <qrcode :value="authenticator_url" :color="{ dark: '#000000ff', light: '#ffffffff' }" errorCorrectionLevel="Q" :width="200" type="image/png"></qrcode>
                    </a>
                </div>
            </div>

            <div class="site-content">
                <div class="content">{{$t('profile.two_factor_authentication.enable_2fa.success_dialog_regeneration_recovery_codes.label')}}</div>
                <div class="content" style="position:relative;">
                    <div class="share-url" style="font-family: monospace;" ref="recovery_codes">{{ recovery_codes.join(', ') }}</div>
                    <div style="position:absolute; top:8px; right:24px;"><div ref="copyToClipBoardButton" id="copyToClipBoardButton" style="color: #4796C1;" @click="copyToClipboard();"><i class="fa-solid fa-clipboard fa-2x"></i></div></div>
                    <div style="display:inline; width:0; height:0;" ref="reference"></div>
                </div>
                <div class="content">
                    {{$t('profile.two_factor_authentication.enable_2fa.success_dialog_regeneration_recovery_codes.infotext')}}
                </div>
            </div>
            <div class="site-content button-right">
                <button type="button" class="button" @click="close_success_2fa_enabled_authenticator()">{{ $t('history.set_new_password_dialog.success_dialog.close_button')}}</button>
            </div>
        </div>
        <div id="success_2fa_enabled_email" v-if="show_success_2fa_enabled_email_dialog">
            <div class="site-title">{{$t('profile.two_factor_authentication.enable_2fa.success_dialog_email.headline')}}</div>
            <div class="site-content">{{$t('profile.two_factor_authentication.enable_2fa.success_dialog_email.infotext')}}</div>
            <div class="site-content button-right">
                <button type="button" class="button" @click="close_success_2fa_enabled_email();">
                    {{ $t('history.set_new_password_dialog.success_dialog.close_button')}}
                </button>
            </div>
        </div>
        <div id="confirmation_2fa_disable" v-if="show_confirmation_2fa_disable_dialog">
            <div class="site-title">{{ $t('profile.two_factor_authentication.disable_2fa.action.confirmation.headline')}}</div>
            <div class="site-content">
                <div class="content">{{ $t('profile.two_factor_authentication.disable_2fa.action.confirmation.infotext')}}</div>
            </div>
            <form class="bodyform">
                <div class="bodyform__item two_buttons">
                    <button type="button" class="button toolbar" @click="cancel_confirmation_2fa_disable();">{{ $t('profile.two_factor_authentication.disable_2fa.action.confirmation.cancel') }}</button>
                    <button type="button" class="button" @click="disable_mfa_authentication();">{{ $t('profile.two_factor_authentication.disable_2fa.action.confirmation.button') }}</button>
                </div>
            </form>
        </div>
        <div id="success_2fa_disabled" v-if="show_success_2fa_disabled_dialog">
            <div class="site-title">{{ $t('profile.two_factor_authentication.disable_2fa.success_dialog_disabled.headline')}}</div>
            <div class="site-content">
                <div class="content">{{$t('profile.two_factor_authentication.disable_2fa.success_dialog_disabled.infotext')}}</div>
            </div>
            <div class="site-content button-right">
                <button type="button" class="button" @click="close_success_2fa_disabled()">{{ $t('history.set_new_password_dialog.success_dialog.close_button')}}</button>
            </div>
        </div>
        <div id="success_uploaded_keys_file" v-if="show_success_uploaded_keys_file_dialog">
            <div class="site-title">{{ $t('profile.personal_key.upload_keys_dialog.success_dialog_uploaded_keys.headline')}}</div>
            <div class="site-content">
                <div class="content">{{$t('profile.personal_key.upload_keys_dialog.success_dialog_uploaded_keys.infotext')}}</div>
            </div>
            <div class="site-content button-right">
                <button type="button" class="button" @click="close_success_uploaded_keys_file_dialog()">{{ $t('profile.personal_key.upload_keys_dialog.success_dialog_uploaded_keys.close_button')}}</button>
            </div>
        </div>
        <div id="success_change_password_dialog" v-if="show_success_change_password_dialog">
            <div class="site-title">{{ $t('profile.change_password.success_dialog.headline')}}</div>
            <div class="site-content">
                <div class="highlighted">{{$t('profile.change_password.success_dialog.infotext')}}</div>
            </div>
            <div class="site-content button-right">
                <button type="button" class="button" @click="close_success_change_password()">{{ $t('history.set_new_password_dialog.success_dialog.close_button')}}</button>
            </div>
        </div>
        <div id="change_password" v-if="show_change_password_dialog">
            <div class="site-title">{{ $t('profile.change_password.form.headline')}}</div>
            <div class="site-content">
                <div class="content" v-html="$t('profile.change_password.form.infotext')"></div>
                <form v-on:submit.prevent="send_change_password" class="bodyform">
                    <div class="bodyform__item">
                        <label for="old-password">{{ $t('profile.change_password.form.labels.password_old')}}:</label>
                        <input id="old-password" name="old-password" type="password" v-model="old_password" autocomplete="new-password" />
                    </div>
                    <div class="bodyform__item">
                        <label for="new-password">{{ $t('profile.change_password.form.labels.password_new')}}:</label>
                        <input id="new-password" name="new-password" type="password" v-model="new_password" autocomplete="new-password" @input="this.$refs.checkpassword_component.checkPasswordStrength(this.new_password)" />
                    </div>
                    <div class="bodyform__item">
                        <CheckPassword ref="checkpassword_component"></CheckPassword>
                    </div>
                    <div class="bodyform__item">
                        <label for="confirm_new-password">{{ $t('profile.change_password.form.labels.password_confirm')}}:</label>
                        <input id="confirm_new-password" name="confirm_new-password" type="password" v-model="confirm_new_password" autocomplete="new-password" />
                    </div>
                    <div class="bodyform__item two_buttons">
                        <button type="button" class="button toolbar" @click="cancel_change_password();">{{ $t('profile.personal_data.change_email_init.cancel')}}</button>
                        <button type="submit" class="button">{{ $t('profile.change_password.form.labels.submit')}}</button>
                    </div>
                </form>
            </div>
        </div>



        <div v-if="show_change_device_name_dialog" class="dialog-modal">
            <div class="dialog-container">
                <div class="page_dialog fadeIn less-wide">
                    <div class="close-button-bar">
                        <div class="button square toolbar" @click="this.show_change_device_name_dialog = false;"><i class="fa-solid fa-xmark"></i></div>
                    </div>
                    <div class="site-title less-wide">{{ $t('profile.linked_devices.change_name.headline')}}</div>
                    <div class="site-content less-wide">{{ $t('profile.linked_devices.change_name.infotext')}}</div>
                    <form v-on:submit.prevent="send_change_device_name" class="bodyform less-wide">
                        <div class="bodyform__item">
                            <label for="device_name">{{ $t('profile.linked_devices.change_name.form.labels.name') }}:</label>
                            <input id="device_name" name="device_name" type="text" v-model="this.current_device_name" autocomplete="off" />
                        </div>

                        <div class="bodyform__item two_buttons">
                            <button class="button toolbar" type="button" @click="this.show_change_device_name_dialog = false;">{{ $t('general.buttons.cancel')}}</button>
                            <button type="button" class="button">{{ $t('profile.linked_devices.change_name.form.labels.submit')}}</button>

                        </div>
                    </form>

                </div>
            </div>
        </div>

        <div id="upload_keys_file" v-if="show_uploaded_keys_file_dialog">
            <div class="site-title">{{ $t('profile.personal_key.upload_keys_dialog.headline')}}</div>
            <div class="site-content">
                <div class="content">{{ $t('profile.personal_key.upload_keys_dialog.infotext')}}</div>
                <form v-on:submit.prevent="loadKeyFromFile" class="bodyform invers">
                    <div class="bodyform__item">
                        <label for="device_name">{{ $t('profile.personal_key.upload_keys_dialog.form.labels.file')}}:</label>
                        <input type="file" id="keyFileInput">
                    </div>
                    <div class="bodyform__item two_buttons">
                        <button type="button" class="button toolbar" @click="cancel_uploaded_keys_file();">{{ $t('profile.personal_key.upload_keys_dialog.form.labels.cancel')}}</button>
                        <button type="submit" class="button">{{ $t('profile.personal_key.upload_keys_dialog.form.labels.submit')}}</button>
                    </div>
                </form>
            </div>
        </div>
        <div id="change_email_init" v-if="show_change_email_init_dialog">
            <div class="site-title">{{ $t('profile.personal_data.change_email_init.headline')}}</div>
            <div class="site-content">
                <div class="content" v-html="$t('profile.personal_data.change_email_init.infotext')"></div>
                <form v-on:submit.prevent="send_change_email_init" class="bodyform">
                    <div class="bodyform__item two_buttons">
                        <button type="button" class="button wide toolbar" @click="cancel_change_email_init();">{{ $t('profile.personal_data.change_email_init.cancel')}}</button>
                        <button type="submit" class="button wide">{{ $t('profile.personal_data.change_email_init.submit')}}</button>
                    </div>
                </form>
            </div>
        </div>
        <div id="change_email_init_success" v-if="show_change_email_init_success_dialog">
            <div class="site-title">{{ $t('profile.personal_data.change_email_init.success_dialog.headline')}}</div>
            <div class="site-content">
                <div class="highlighted" v-html="$t('profile.personal_data.change_email_init.success_dialog.infotext')"></div>
            </div>
            <div class="site-content button-right">
                <button type="button" class="button" @click="show_change_email_init_success_dialog = false; show_main=true;">{{ $t('profile.personal_data.change_email_init.success_dialog.close')}}</button>
            </div>
        </div>
        <div id="change_email_request" v-if="show_change_email_request_dialog">
            <div class="site-title">{{ $t('profile.personal_data.change_email_request.headline')}}</div>
            <div class="site-content">
                <div class="content" v-html="$t('profile.personal_data.change_email_request.infotext')"></div>
                <form v-on:submit.prevent="send_change_email_request" class="bodyform">
                    <div class="bodyform__item">
                        <label for="new_email_addy">{{ $t('profile.personal_data.change_email_request.form.labels.email_new')}}:</label>
                        <input id="new_email_addy" name="new_email_addy" type="email" required v-model="this.new_email_addy" autocomplete="off" />
                    </div>
                    <div class="bodyform__item two_buttons">
                        <button type="button" class="button toolbar" @click="this.show_change_email_request_dialog = false;">{{ $t('profile.personal_data.change_email_request.form.labels.cancel')}}</button>
                        <button type="submit" class="button">{{ $t('profile.personal_data.change_email_request.form.labels.submit')}}</button>
                    </div>
                </form>
            </div>
        </div>
        <div id="change_email_request_success" v-if="show_change_email_request_success_dialog">
            <div class="site-title">{{ $t('profile.personal_data.change_email_request.success_dialog.headline')}}</div>
            <div class="site-content">
                <div class="highlighted" v-html="$t('profile.personal_data.change_email_request.success_dialog.infotext')"></div>
            </div>
            <div class="site-content button-right">
                <button type="button" class="button" @click="close_change_email_request_success_dialog(); show_main=true;">{{ $t('profile.personal_data.change_email_init.success_dialog.close')}}</button>
            </div>
        </div>
    </div>

</template>
<style>
</style>

<script>
    import Qrcode from 'vue-qrcode';
    import CustomDropdown from '../components/CustomDropdown.vue';
    import CheckPassword from '../components/CheckPassword.vue';

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';
     
    export default {
        name: 'Profile',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            Qrcode,
            CustomDropdown,
            CheckPassword,
        },
        props: {
            url_param: '',
            token:''
          
        },
        data: () => ({
            show_main: true,
            show_success_2fa_enabled_authenticator_dialog: 0,
            show_success_2fa_enabled_email_dialog: false,
            show_success_2fa_disabled_dialog: false,
            show_confirmation_2fa_disable_dialog: false,
            show_selection_authentication_method_email: false,
            show_selection_authentication_method_auth_app: false,
            show_change_password_dialog: false,
            show_success_change_password_dialog: false,
            show_change_device_name_dialog: false,
            show_uploaded_keys_file_dialog: false,
            show_success_uploaded_keys_file_dialog: false,

            show_change_email_init_dialog: false,
            show_change_email_init_success_dialog: false,
            show_change_email_request_dialog: false,
            show_change_email_request_success_dialog: false,

            user_email: '',
            two_factor_enabled: false,
            two_factor_token_provider: '',
            recovery_codes_count: 0,
            last_key_backup_date: '',
            recovery_codes: [],
            linked_devices: [],
            authenticator_url: '',
            

            confirm_new_password: '',
            old_password: '',
            new_password: '',
            current_device_id: '',
            current_device_name: '',
            new_email_addy: '',
            confirmation_token: '',
            two_factor_authentication_methods: [],
           

        }),
        computed: {
            own_device_id() {
                let jwtDecode = require('jwt-decode');
                //let jwtLib = require('jsonwebtoken');
                let jwt = localStorage.getItem("jwt");
                //const decodedPayload = jwtLib.decode(jwt);
                const decodedPayload = jwtDecode.jwtDecode(jwt);                
                return decodedPayload.device;
            },
            
            
        },
        methods: {
            async get_profile() {

                this.two_factor_authentication_methods =
                    [
                        { label: 'profile.two_factor_authentication.enable_2fa.form.options.email', value: 'EMail' },
                        { label: 'profile.two_factor_authentication.enable_2fa.form.options.appauth', value: 'Authenticator' },
                    ];

                await this.get_account_infos();
                await this.get_linked_devices();
            },
            async get_account_infos() {
                const url = "account/";

                try {
                    const response = await globals.make_Xhr_Request("GET", url);

                    if (response.status === 200) {
                        const payload = JSON.parse(response.responseText).payload;
                        this.user_email = payload.email;
                        this.two_factor_enabled = payload.two_factor_enabled;
                        this.two_factor_token_provider = payload.two_factor_token_provider;
                        this.recovery_codes_count = payload.recovery_codes_count;
                        this.last_key_backup_date = payload.last_key_backup_date;
                   
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }

                } catch (e) {
                    console.error(e);
                    globals.show_error('errors.network_error');
                }
                finally {
                    globals.hide_loading();
                }
            },
            async get_linked_devices() {

                const url = "account/linked-devices";
                try {
                    const response = await globals.make_Xhr_Request("GET", url);

                    if (response.status === 200) {
                        let result = JSON.parse(response.responseText);
                        this.linked_devices = result.payload.linked_devices;
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }

                } catch (e) {
                    console.error(e);
                    globals.show_error('errors.network_error');
                }
                finally {
                    globals.hide_loading();
                }
            },
            async enable_mfa_authentication() {
                const url = "account/mfa-enable/true";
                try {
                    const data = { "token_provider": this.two_factor_token_provider, "with_recovery_codes": true };
                    const response = await globals.make_Xhr_Request("PATCH", url, data);                    

                    if (response.status === 200) {
                        const payload = JSON.parse(response.responseText).payload;
                        if (payload.recovery_codes) {
                            this.recovery_codes = payload.recovery_codes;
                            this.authenticator_url = payload.authenticator_url;
                            this.show_success_2fa_enabled_authenticator_dialog = 1;
                            this.show_main = false;
                        } else {
                            this.show_success_2fa_enabled_email_dialog = true;
                            this.show_main = false;
                        }
                        await this.get_profile();

                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }

                } catch (e) {
                    console.error(e);
                    globals.show_error('errors.network_error');
                }
                finally {
                    globals.hide_loading();
                }
            },
            async disable_mfa_authentication() {

                const url = "account/mfa-enable/false";

                try {
                    const response = await globals.make_Xhr_Request("PATCH", url);

                    if (response.status === 200) {
                        this.show_confirmation_2fa_disable_dialog = false;
                        this.show_success_2fa_disabled_dialog = true;
                        this.show_main = false;
                        await this.get_profile();
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }

                } catch (e) {
                    console.error(e);
                    globals.show_error('errors.network_error');
                }
                finally {
                    globals.hide_loading();
                }
            },
            async send_change_password() {

                if ((this.new_password + "") !== (this.confirm_new_password + "")) {
                    globals.show_error('errors.password_not_match');
                    return;
                }
                if (!this.$refs.checkpassword_component.checkPasswordStrength(this.new_password)) {
                    globals.show_error('errors.password_requirements_failed');
                    return;
                }

                if (!security.client_salt ||
                    !security.personal_key) {
                    globals.show_error('errors.fatal_error');
                    //ToDo: Automatischer Download des DBKeys
                }
                try {
                    //ein neuen client_hash aus dem neuen PW erstellen
                    const new_client_salt = security.generate_salt();
                    const new_client_hash = await security.hash_pw_with_salt(this.new_password, new_client_salt);
                    //ein neuen personal_key erstellen
                    const new_personal_key = await security.generate_personal_key(new_client_hash, new_client_salt);
                    //alle alten verschlüsselten dbKeys holen
                    const url_step1 = "account/get-client-database-keys";

                    const response_step1 = await globals.make_Xhr_Request("GET", url_step1);

                    if (response_step1.status === 200) {
                        const payload = JSON.parse(response_step1.responseText).payload;
                        let encrypted_database_keys = [];
                        for (const edbk of payload.encrypted_database_keys) {
                            //jeden alten DBKey entschlüsseln
                            //den dbKey mit dem alten personal_key entschlüsseln
                            const dbkey = await security.process_decryption_as_crypto_key(edbk.encrypted_database_key, edbk.database_key_iv, security.personal_key)
                            //den dbKey mit dem neuen personalKey verschlüsseln
                            const edki = await security.process_encryption(dbkey, new_personal_key);
                            encrypted_database_keys.push({ "database_key_id": edbk.database_key_id, "encrypted_database_key": edki.encryptedOutput, "database_key_iv": edki.iv })
                        }
                        //die neu verschlüsselten dbKeys, samt neuem IV mit dem ChangePW an den Server schicken
                        let new_pw_hash = await security.hash_pw(this.new_password)
                        let old_pw_hash = await security.hash_pw(this.old_password)

                        const url_step2 = "account/change-password";
                        var data = { "current_password": old_pw_hash, "new_password": new_pw_hash, "new_client_salt": new_client_salt, "encrypted_database_keys": encrypted_database_keys };

                        this.new_password = '';
                        this.confirm_new_password = '';
                        this.old_password = '';

                        const response_step2 = await globals.make_Xhr_Request("PATCH", url_step2, data);
                        if (response_step2.status === 200) {

                            const payload = JSON.parse(response_step2.responseText).payload;
                            security.client_salt = payload.client_salt;
                            security.encrypted_database_key = payload.encrypted_database_key;
                            security.database_key_iv = payload.database_key_iv;
                            security.database_key_id = payload.database_key_id;

                            this.show_change_password_dialog = false;
                            this.show_success_change_password_dialog = true;
                        } else if (response_step2.status === 401) {
                            globals.show_error('errors.change_password_failed');
                        } else {
                            globals.handle_Xhr_Errors(response_step2.status, response_step2.responseText);
                        }

                    } else {
                        globals.handle_Xhr_Errors(response_step1.status, response_step1.responseText);
                    }

                } catch (e) {
                    globals.show_error('errors.change_password_failed');
                }
                finally {
                    globals.hide_loading();
                }
            },
            async generate_recovery_codes() {
                const url = "account/generate-recoverycodes";

                try {
                    const response = await globals.make_Xhr_Request("GET", url);

                    if (response.status === 200) {
                        const payload = JSON.parse(response.responseText).payload;
                        this.recovery_codes = payload.recovery_codes;
                        this.show_success_2fa_enabled_authenticator_dialog = 2;
                        this.show_main = false;
                        await this.get_profile();
                    } else if (response.status === 401) {
                        globals.show_error('errors.change_password_failed');
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }

                } catch (e) {
                    globals.show_error('errors.network_error');
                }
                finally {
                    globals.hide_loading();
                }
            },
            async revoke_linked_device(event){
                if (confirm(this.$i18next.t('history.confirmations.delete_download'))) {
                    const url = "account/linked-devices";
                    let id = event.target.parentElement.getAttribute('data-id');
                    let theCurrentItem = this.linked_devices.find(d => d.id == id);
                    if (!theCurrentItem) {
                        globals.show_error('errors.unknown_datarecord');
                        return;
                    } 

                    var data = {
                        "id": theCurrentItem.id,
                        "name": theCurrentItem.name,
                        "remove": true,
                        "revocation_reason": "",
                    };
                    var dataList = { "linked_devices": [data] };

                    try {
                        const response = await globals.make_Xhr_Request("PATCH", url, dataList);

                        if (response.status === 200) {
                            self.get_linked_devices();
                        
                        } else {
                            globals.handle_Xhr_Errors(response.status, response.responseText);
                        }

                    } catch (e) {
                        globals.show_error('errors.network_error');
                    }
                    finally {
                        globals.hide_loading();
                    }
                }
            },
            show_change_device_name(event) {
                this.show_change_device_name_dialog = true;
                
                let id = event.target.parentElement.getAttribute('data-id');
                let theCurrentItem = this.linked_devices.find(d => d.id == id);
                if (theCurrentItem)
                {
                    this.current_device_id = id;
                    this.current_device_name = theCurrentItem.name;
                }
                else {
                    globals.show_error('errors.unknown_datarecord');
                }
            },
            
            async send_change_device_name() {

                const url = "account/linked-devices";
                var data = {
                    "id": this.current_device_id,
                    "name": this.current_device_name
                };
                var dataList = { "linked_devices": [data] };

                try {
                    const response = await globals.make_Xhr_Request("PATCH", url, dataList);

                    if (response.status === 200) {
                        this.show_change_device_name_dialog = false;
                        this.current_device_id = '';
                        this.current_device_name = '';
                        await this.get_linked_devices();
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }

                } catch (e) {
                    globals.show_error('errors.network_error');
                }
                finally {
                    globals.hide_loading();
                }
            },
            async send_change_email_init() {
                const url = "account/change-email";

                try {
                    const response = await globals.make_Xhr_Request("GET", url);

                    if (response.status === 200) {
                        this.show_change_email_init_dialog = false;
                        this.show_change_email_init_success_dialog = true;

                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }

                } catch (e) {
                    globals.show_error('errors.network_error');
                }
                finally {
                    globals.hide_loading();
                }
            },
            show_change_password() {
                this.show_main = false;
                this.show_change_password_dialog = true;
            },
            
            cancel_change_password() {
                this.show_main = true;
                this.show_change_password_dialog = false;
            },
            
            show_change_email_init() {
                this.show_main = false;
                this.show_change_email_init_dialog = true;
            },
            cancel_change_email_init() {
                this.show_main = true;
                this.show_change_email_init_dialog = false;
            },
            show_uploaded_keys_file() {
                this.show_main = false;
                this.show_uploaded_keys_file_dialog = true;
            },
            cancel_uploaded_keys_file() {
                this.show_main = true;
                this.show_uploaded_keys_file_dialog = false;
            },
            show_confirmation_2fa_disable() {
                this.show_main = false;
                this.show_confirmation_2fa_disable_dialog = true;
            },
            cancel_confirmation_2fa_disable() {
                this.show_main = true;
                this.show_confirmation_2fa_disable_dialog = false;
            },
            show_change_email_request() {

                if (this.confirmation_token) {
                    var json = helper.getJsonFromBase64(this.confirmation_token);

                    if (json.email === security.current_username) {
                        this.show_change_email_request_dialog = true;
                    } else {
                        globals.show_error('errors.confirmation_token_dont_match_account');

                    }
                }
            },
            async send_change_email_request() {
                if (security.current_username === this.new_email_addy.toLowerCase()) {
                    globals.show_error('errors.email_already_using');
                    return;
                }
                const url = "account/change-email";
                var data = { "confirmation_token": this.confirmation_token, "new_email": this.new_email_addy };

                try {
                    const response = await globals.make_Xhr_Request("PATCH", url, data);

                    if (response.status === 200) {
                        this.show_change_email_request_dialog = false;
                        this.show_change_email_request_success_dialog = true;
                    } else if (response.status === 405) {
                        globals.show_error('errors.email_rejected');
                    } else if (response.status === 406) {
                        globals.show_error('errors.email_already_using');
                    } else if (response.status === 409) {
                        globals.show_error('errors.email_already_existing');
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }

                } catch (e) {
                    globals.show_error('errors.network_error');
                }
                finally {
                    globals.hide_loading();
                }
            },
            close_change_email_request_success() {
                this.show_change_email_request_success_dialog = false;
                this.confirmation_token = '';
                this.new_email_addy = '';
                globals.goto_new_upload()();
            },
            show_success_change_password() {
                this.show_change_password_dialog = false;
                this.show_success_change_password_dialog = true;
                this.show_main = false;
            },
            close_success_2fa_enabled_authenticator() {
                this.show_success_2fa_enabled_authenticator_dialog = 0;
                this.show_main = true;
            },
            close_success_2fa_enabled_email() {
                this.show_success_2fa_enabled_email_dialog = false;
                this.show_main = true;
            },
            close_success_2fa_disabled() {
                this.show_success_2fa_disabled_dialog = false;
                this.show_main = true;
            },
            close_success_uploaded_keys_file_dialog() {
                this.show_success_uploaded_keys_file_dialog = false;
                this.show_main = true;
            },
            async close_success_change_password() {
                this.show_success_change_password_dialog = false;
                globals.loggedIn = false;
                await security.logout(true);
                this.$router.push('/login');
            },

            async download_dbKey() {
                try {
                    const url_step1 = "account/get-client-database-keys";

                    const response_step1 = await globals.make_Xhr_Request("GET", url_step1);
                       
                    if (response_step1.status === 200) {
                        const payload = JSON.parse(response_step1.responseText).payload;
                        await security.download_decrypted_key(payload.encrypted_database_keys, "decryptedKey.key", security.personal_key);

                        const url_step2 = "account/client-database-keys-saved";
                        const data_step2 = { ids: payload.encrypted_database_keys.map(d => d.database_key_id) };

                        const response_step2 = await globals.make_Xhr_Request("PATCH", url_step2, data_step2);

                        if (response_step2.status === 200) {
                            await this.get_profile();
                        } else {
                            globals.handle_Xhr_Errors(response_step2.status, response_step2.responseText);
                        }
                    } else {
                        globals.handle_Xhr_Errors(response_step1.status, response_step1.responseText);
                    }
                } catch (e) {
                    console.error(e);
                    globals.show_error('errors.internal');
                }
                finally {
                    globals.hide_loading();
                }

            },
            async loadKeyFromFile() {
                console.log("loadKeyFromFile");
                try {
                    const fileInput = document.getElementById("keyFileInput");
                    const file = fileInput.files[0];

                    if (!file) {
                        alert("Bitte wählen Sie eine Datei aus.");
                        return;
                    }
                    const importedInfos = await security.load_and_encrypt_Keys_from_File(file, security.personal_key);
                    const url = "account/set-client-database-keys";
                    const data = { encrypted_database_keys: importedInfos };
                    console.log("data", data);

                    const response = await globals.make_Xhr_Request("PATCH", url, data);

                    if (response.status === 200) {
                        this.show_uploaded_keys_file_dialog = false;
                        this.show_success_uploaded_keys_file_dialog = true;
                        await security.refresh_jwt();
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    globals.show_error('errors.internal');
                }
                finally {
                    globals.hide_loading();
                }
            },

            
            copyToClipboard() {
                const storage = document.createElement('textarea');
                storage.value = this.$refs.recovery_codes.textContent;
                this.$refs.reference.appendChild(storage);
                storage.select();
                storage.setSelectionRange(0, 99999);
                var cbb = this.$refs.copyToClipBoardButton
                try {
                    // Versuche, den ausgewählten Text in die Zwischenablage zu kopieren
                    document.execCommand('copy');
                    cbb.innerHTML = '<i class="fa-solid fa-check fa-2x"></i>';
                    setTimeout(() => {
                        cbb.innerHTML = '<i class="fa-solid fa-clipboard fa-2x"></i>';
                    }, 500);
                    //alert('Link wurde in die Zwischenablage kopiert!');
                } catch (err) {
                    console.error(err);
                    cbb.innerHTML = '<i class="fa-solid fa-square-exclamation fa-2x"></i>';
                    setTimeout(() => {
                        cbb.innerHTML = '<i class="fa-solid fa-clipboard fa-2x"></i>';
                    }, 1000);
                }
                this.$refs.reference.removeChild(storage);
                // Entferne die Auswahl, um das visuelle Feedback zu geben
            },
            handleOptionSelected(selectedOption) {
                if (selectedOption.value === 'EMail') {
                    this.show_selection_authentication_method_email = true;
                    this.show_selection_authentication_method_auth_app = false;
                } else {
                    this.show_selection_authentication_method_email = false;
                    this.show_selection_authentication_method_auth_app = true;
                }
                this.two_factor_token_provider = selectedOption.value;
            },
            handleKeyDown(event) {
                if (event.key === 'Escape') {
                    if (this.show_change_email_init_dialog) {
                        this.cancel_change_email_init();
                    }
                    else if (this.show_change_password_dialog) {
                        this.cancel_change_password();
                    }

                    //if (this.show_success_2fa_enabled_authenticator_dialog > 0) {
                    //    this.show_success_2fa_enabled_authenticator_dialog = 0;
                    //    this.recovery_codes = [];
                    //    this.authenticator_url = '';
                    //}
                    //else if (this.show_success_2fa_enabled_email_dialog) {
                    //    this.show_success_2fa_enabled_email_dialog = false;
                    //}
                    //else if (this.show_success_2fa_disabled_dialog) {
                    //    this.show_success_2fa_disabled_dialog = false;
                    //}
                    //else if (this.show_confirmation_2fa_disable_dialog) {
                    //    this.show_confirmation_2fa_disable_dialog = false;
                    //}

                    //else if (this.show_success_change_password_dialog) {
                    //    this.close_success_change_password_dialog();
                    //}

                    //else if (this.show_change_email_request_dialog) {
                    //    this.show_change_email_request_dialog = false;
                    //}
                    //else if (this.show_change_email_init_success_dialog) {
                    //    this.show_change_email_init_success_dialog = false;
                    //}
                    //else if (this.show_change_email_request_success_dialog) {
                    //    this.show_change_email_request_success_dialog = false;
                    //}
                    //else if (this.show_uploaded_keys_file_dialog) {
                    //    this.show_uploaded_keys_file_dialog = false;
                    //}
                    //else if (this.show_success_uploaded_keys_file_dialog) {
                    //    this.show_success_uploaded_keys_file_dialog = false;
                    //}
                    //else if (this.show_change_device_name_dialog) {
                    //    this.show_change_device_name_dialog = false;
                    //    this.device_name = "";
                    //}
                }
            },
            async show_me() {
                if (!globals.loggedIn) {
                    this.$router.push('/');
                    return;
                }

                if (this.$route.name === 'Profile_init_change_email' && this.token) {
                    this.confirmation_token = this.token;
                    this.show_change_email_request();
                } else {
                    await this.get_profile();    
                }
                globals.setPageTitel("profile.headline");
                

            }
        },
        watch: {
            'globals.loggedIn': {
                handler: async function () {
                    await this.show_me();
                }
            }
        },
        mounted: async function () {
            await this.show_me();
            document.addEventListener('keydown', this.handleKeyDown);
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        },
        updated() {
        }
    }
</script>