<!-- src/components/StatusGrid.vue -->
<template>
    <div>
        <!--<span>{{filterValue}}</span>-->
        <!--<p-slider v-model="filterValue" :min="minResponseTime" :max="maxResponseTime" :step="100" @change="filterData" />-->
        <div style="display: flex; justify-content: end; width: 100%">
            <div style="">
                <p-slider v-model="filterValue" :min="minResponseTime" :max="maxResponseTime" :step="10" @change="filterData" style="width:200px;" /><br /><br />
            </div>

        </div>
        
        <div class="status-grid-container" ref="statusGridContainer">
            <svg :width="svgWidth" :height="rectHeight">
                <rect v-for="(item, index) in filteredData"
                      :key="index"
                      :x="getX(index)"
                      :y="0"
                      :width="rectWidth"
                      :height="rectHeight"
                      :fill="getColor(item)"
                      v-tippy="() => getTooltipContent(item.id)"
                      >
                    <!--v-tippy="() => getTooltipContent(item.id)"-->
                    <template v-show="false">
                        <div :id="'tooltip-' + item.id">
                            <div :style="{ padding: '10px', 'border-left': '2px solid ' + getColor(item) }">
                                <p><strong>Status:</strong> {{item.has_error ? 'Error' : '!OK!'}}</p>
                                <p><strong>Response Time:</strong> {{item.response_time_in_ms}} ms</p>
                                <p><strong>Gemessen um:</strong> {{helper.formatReadableDate(item.measured_at, globals.selected_language)}}</p>
                                <p><strong>Response:</strong> <span v-html="formatJson(item.result_content)"></span></p>
                                <p><strong>Color:</strong> {{getColor(item)}}</p>
                            </div>

                        </div>
                    </template>
                </rect>
                <!--v-tooltip="{ value: getTooltipContent(item), escape: false, autoAdjust: true} "-->
            </svg>
        </div>
    </div>
</template>

<script>
    import Slider from 'primevue/slider';
    import helper from '@common_utils/Helper';
    import globals from '@common_utils/Globals';

    export default {
        setup() {
            return {
                helper, globals
            };
        },
        components: {
            'p-slider': Slider,
        },
        
        props: {
            data: {
                type: Array,
                required: true
            },
            rectWidth: {
                type: Number,
                default: 5
            },
            rectHeight: {
                type: Number,
                default: 34
            },
            spacing: {
                type: Number,
                default: 3
            }
        },
        data() {
            return {
                filterValue: 0,
                filteredData: this.data
            };
        },
        computed: {
            svgWidth() {
                return this.filteredData.length * (this.rectWidth + this.spacing);
            },
            filteredResponseTimes() {
                return this.data
                    .filter(item => !item.has_error)
                    .map(item => item.response_time_in_ms);
            },
            minResponseTime() {
                return Math.min(...this.filteredResponseTimes);
            },
            maxResponseTime() {
                return Math.max(...this.filteredResponseTimes);
            }
        },
        methods: {
            getX(index) {
                return index * (this.rectWidth + this.spacing);
            },
            getColor(item) {
                if (item.has_error) {
                    return 'red';
                }
                return item.response_time_in_ms > 1000
                    ? this.getYellowToOrangeShade(item.response_time_in_ms)
                    : this.getGreenShade(item.response_time_in_ms);
            },
            getGreenShade(responseTime) {
                const min = this.minResponseTime;
                const max = Math.min(this.maxResponseTime, 1000);
                const normalized = (responseTime - min) / (max - min);
                const greenValue = Math.floor(100 + (normalized * 150)); // Verst�rkt die Differenz
                return `rgb(0, ${greenValue}, 0)`;
            },
            getYellowToOrangeShade(responseTime) {
                const min = 1000;
                const max = this.maxResponseTime;
                const normalized = (responseTime - min) / (max - min);
                const redValue = Math.floor(255);
                const greenValue = Math.floor(255 - (127 * normalized));
                return `rgb(${redValue}, ${greenValue}, 0)`;
            },
            filterData() {
                this.filteredData = this.data.filter(item => {
                    if (item.has_error) return true;
                    return item.response_time_in_ms >= this.filterValue;
                });
            },
            getSliderColor(responseTime) {
                if (responseTime > 1000) {
                    return this.getYellowToOrangeShade(responseTime);
                }
                return this.getGreenShade(responseTime);
            },
            updateSliderColor(responseTime) {
                const color = this.getSliderColor(responseTime);
                const sliderRange = this.$el.querySelector('.p-slider-range');
                if (sliderRange) {
                    sliderRange.style.backgroundColor = color;
                }
            },
            getTooltipContent(index) {
                const template = this.$el.querySelector(`#tooltip-${index}`);
                return template.innerHTML;;
            },
            formatJson(jsonInput) {
                try {
                    const jsonObject = JSON.parse(jsonInput);
                    return this.convertToHtml(jsonObject);
                } catch (error) {
                    return 'Ung�ltiges JSON ' + error;
                }
            },
            convertToHtml(obj, indent = 0) {
                let html = '<div style="padding-left: ' + indent + 'px;">';

                for (let key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        if (typeof obj[key] === 'object' && obj[key] !== null) {
                            html += '<div><strong>' + key + ':</strong></div>';
                            html += this.convertToHtml(obj[key], indent + 20);
                        } else {
                            html += '<div><strong>' + key + ':</strong> ' + obj[key] + '</div>';
                        }
                    }
                }

                html += '</div>';
                return html;
            }
        },
        watch: {
            data: {
                immediate: true,
                handler() {
                    this.filterValue = this.minResponseTime;
                    this.filterData();
                }
            },
            filterValue(newValue) {
                this.updateSliderColor(newValue);
            }
        },
        mounted() {
            this.$nextTick(() => {
                const container = this.$refs.statusGridContainer;
                container.scrollLeft = container.scrollWidth;
                this.filterValue = this.minResponseTime;
                ////this.filterValue = 200;
                //console.log("this.filterValue ", this.filterValue);
                //this.updateSliderColor(this.filterValue);
            });
        }
    }
</script>

<style scoped>
    .status-grid-container {
        width: 100%;
        overflow-x: scroll;
        padding: 5px 0px;
        box-sizing: border-box;
    }
</style>
