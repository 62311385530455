<template>
    <div v-if="globals.is_login_dialog_visible" class="dialog-modal less-wide">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="site-title less-wide">{{ $t('login.headline')}}</div>

                <form v-on:submit.prevent="send_login" class="bodyform less-wide">
                    <div class="bodyform__item">
                        <label for="username">{{ $t('login.form.labels.username')}}</label>
                        <input type="text" id="username" v-model="username">
                    </div>
                    <div class="bodyform__item">
                        <label for="password">{{ $t('login.form.labels.password')}}</label>
                        <input type="password" id="password" v-model="security.password">
                    </div>
                    <div class="bodyform__item button-right">
                        <div style="padding-top: 5px;"><a :href="globals.get_forgot_pw_url()">{{ $t('login.form.labels.forgot_password')}}</a></div>
                    </div>
                    <div class="bodyform__item two_buttons">
                        <button type="submit" class="button">{{ $t('login.form.labels.submit')}}</button>
                        <button v-if="this.$route.name != 'Login'" type="button" class="button toolbar" @click="this.close_login_dialog()">{{ $t('general.buttons.cancel')}}</button>
                        <button v-if="this.$route.name === 'Login'" type="button" class="button toolbar" @click="globals.goto_new_upload();">{{ $t('general.buttons.cancel')}}</button>
                    </div>
                </form>
                <div class="site-content less-wide">
                    <div style="padding-top:20px;" v-html="$t('login.form.labels.goto_register_text', {url_register: globals.get_register_url(),url_more_features: globals.get_more_features_url()})"></div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="this.is_forgot_password_dialog_visible">
        <div class="site-title">{{ $t('login.forgot_password.headline')}}</div>
        <div class="site-content">
            <div class="content">
                <div style="padding-top:5px;" v-html="$t('login.forgot_password.infotext')"></div>
            </div>
            <form v-on:submit.prevent="send_forgot_password" class="bodyform">
                <div class="bodyform__item">
                    <label for="username">{{ $t('login.forgot_password.form.labels.username')}}:</label>
                    <input type="text" id="username" v-model="username">
                </div>
                <div class="bodyform__item">
                    <button style="float: left;" type="submit" class="button">{{ $t('login.forgot_password.form.labels.submit')}}</button>
                    <div style="clear:both;"></div>
                </div>
            </form>
        </div>
    </div>
    <div v-if="this.is_set_new_password_dialog_visible">
        <div class="site-title">{{ $t('login.set_new_password.headline')}}</div>
        <div class="site-content">
            <form v-on:submit.prevent="send_new_password" class="bodyform">
                <div class="bodyform__item">
                    <label for="new-password">{{ $t('login.set_new_password.form.labels.password')}}:</label>
                    <input id="new-password" name="new-password" type="password" v-model="new_password" autocomplete="new-password" @input="this.$refs.checkpassword_component.checkPasswordStrength(this.new_password)" />
                </div>
                <div class="bodyform__item">
                    <CheckPassword ref="checkpassword_component"></CheckPassword>
                </div>
                <div class="bodyform__item">
                    <label for="confirm_new-password">{{ $t('login.set_new_password.form.labels.password_confirm')}}:</label>
                    <input id="confirm_new-password" name="confirm_new-password" type="password" v-model="confirm_new_password" autocomplete="new-password" />
                </div>
                <div class="bodyform__item">
                    <button style="float: left;" type="submit" class="button">{{ $t('login.set_new_password.form.labels.submit')}}</button>
                    <div style="clear:both;"></div>
                </div>
            </form>
        </div>
    </div>
    <div v-if="this.is_resend_confirmation_dialog_visible">
        <div class="site-title">{{ $t('login.headline')}}</div>
        <div class="site-content">
            <div class="highlighted">
                {{$t('login.resend_confirmation.headline')}}
            </div>
            <div style="padding-top:20px;padding-left:9px;">{{ $t('login.resend_confirmation.labels.resend_text_before')}} <a @click="resend_confirmation();">{{ $t('login.resend_confirmation.labels.resend_button')}}</a> {{ $t('login.resend_confirmation.labels.resend_text_after')}}</div>
        </div>
    </div>
    <div v-if="!globals.loggedIn && (this.is_2fa_code_dialog_visible == 1 || this.is_2fa_code_dialog_visible == 2)" class="dialog-modal less-wide">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div v-if="this.is_2fa_code_dialog_visible==2" class="site-title less-wide">{{ $t('login.2fa_code_dialog.headlines.auth_app')}}</div>
                <div v-if="this.is_2fa_code_dialog_visible==1" class="site-title less-wide">{{ $t('login.2fa_code_dialog.headlines.email_code')}}</div>
                <div class="site-content less-wide">
                    <div v-if="this.is_2fa_code_dialog_visible==2">{{ $t('login.2fa_code_dialog.infotexts.auth_app')}}</div>
                    <div v-if="this.is_2fa_code_dialog_visible==1">{{ $t('login.2fa_code_dialog.infotexts.email_code')}}</div>
                </div>
                <form v-on:submit.prevent="send_2fa_code" class="bodyform less-wide">
                    <div class="bodyform__item">
                        <label v-if="this.is_2fa_code_dialog_visible==2" for="twofa_code">{{ $t('login.2fa_code_dialog.form.labels.auth_app')}}</label>
                        <label v-if="this.is_2fa_code_dialog_visible==1" for="twofa_code">{{ $t('login.2fa_code_dialog.form.labels.email_code')}}</label>
                        <input id="twofa_code" name="twofa_code" type="text" v-model="twofa_code" autocomplete="new-password" />
                    </div>
                    <div v-if="this.is_2fa_code_dialog_visible==2" class="bodyform__item button-right">
                        <div style="padding-top: 5px;">
                            <a @click="this.is_2fa_code_dialog_visible=3">{{ $t('login.2fa_code_dialog.form.labels.switch_recovery_code')}}</a>
                        </div>
                    </div>
                    <div class="bodyform__item two_buttons">
                        <button type="submit" class="button">{{ $t('login.2fa_code_dialog.form.labels.submit')}}</button>
                        <button type="button" class="button toolbar" @click="this.close_login_dialog();">{{ $t('general.buttons.cancel')}}</button>

                    </div>
                </form>
            </div>
        </div>
    </div>
    <div v-if="this.is_2fa_code_dialog_visible == 3 && !globals.loggedIn" class="dialog-modal less-wide">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div v-if="this.is_2fa_code_dialog_visible==3" class="site-title less-wide">{{ $t('login.2fa_code_dialog.headlines.recovery_code')}}</div>
                <div class="site-content less-wide">
                    {{ $t('login.2fa_code_dialog.infotexts.recovery_code')}}
                </div>
                <form v-on:submit.prevent="send_2fa_recoverycode" class="bodyform less-wide">
                    <div class="bodyform__item">
                        <label for="twofa_code">{{ $t('login.2fa_code_dialog.form.labels.recovery_code')}}</label>
                        <input id="twofa_code" name="twofa_code" type="text" v-model="twofa_code" autocomplete="new-password" />
                    </div>
                    <div class="bodyform__item button-right">
                        <div style="padding-top: 5px;">
                            <a @click="this.is_2fa_code_dialog_visible = 2;">{{ $t('login.2fa_code_dialog.form.labels.switch_app_auth')}}</a>
                        </div>
                   </div>
                    <div class="bodyform__item two_buttons">
                        <button style="float: left;" type="submit" class="button">{{ $t('login.2fa_code_dialog.form.labels.submit')}}</button>
                        <button type="button" class="button toolbar" @click="this.close_login_dialog();">{{ $t('general.buttons.cancel')}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div v-if="is_success_resend_confirmation_dialog_visible">
        <div class="site-title">{{ $t('login.headline') }}</div>
        <div class="site-content">
            <div class="highlighted">
                {{$t('login.resend_confirmation.success_dialog.headline')}}
            </div>
        </div>
    </div>
    <div v-if="is_success_forgot_passwort_dialog_visible">
        <div class="site-title">{{ $t('login.forgot_password.success_dialog.headline') }}</div>
        <div class="site-content">
            <div class="highlighted">
                {{$t('login.forgot_password.success_dialog.infotext')}}
            </div>
        </div>
    </div>

    <div v-if="is_success_set_passwort_dialog_visible">
        <div class="site-title">{{ $t('login.set_new_password.success_dialog.headline') }}</div>
        <div class="site-content">
            <div class="highlighted">
                {{$t('login.set_new_password.success_dialog.infotext')}}
            </div>
        </div>
    </div>
    <div class="modal" v-if="is_change_email_confirmation_success_dialog_visible">
        <div class="site-title">{{ $t('login.change_email_confirmation.success_dialog.headline') }}</div>
        <div class="site-content">
            <div class="highlighted" v-html="$t('login.change_email_confirmation.success_dialog.infotext')">
            </div>
        </div>
    </div>
</template>

<script>
    import CheckPassword from '../components/CheckPassword.vue';
    import MarketingPanel from '../components/MarketingPanel.vue';

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';
    
    export default {
        name: 'Login',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            CheckPassword,
            MarketingPanel
        },
        props: {
            url_param: '',
            token:''
            
        },
        data: () => ({
            username: '',
            new_password: '',
            confirm_new_password: '',
            twofa_code: '',
            reset_password_token: '',
            confirm_forgotpw_token: '',
            
            is_resend_confirmation_dialog_visible: false,

            is_success_resend_confirmation_dialog_visible: false,

            is_forgot_password_dialog_visible: false,
            is_success_forgot_passwort_dialog_visible: false,

            is_2fa_code_dialog_visible: 0,
            
            is_set_new_password_dialog_visible: false,
            is_success_set_passwort_dialog_visible: false,
            is_change_email_confirmation_success_dialog_visible: false,
        }),
        methods: {
            async send_login() {

                let pw_hash = await this.security.hash_pw(security.password)
                const url = "authenticate/login";
                const data = { "username": this.username, "password": pw_hash, "provider": globals.provider, "service": globals.service };

                try {
                    const response = await globals.make_Xhr_Request("POST", url, data);

                    if (response.status === 200) {
                        const payload = JSON.parse(response.responseText).payload;
                        if (payload.access_token && payload.token_provider) {
                            this.show_mfa_authentication(payload);
                        } else {
                            await this.security.process_post_login(payload);
                            await this.handle_success_login();                            
                        }
                    } else if (response.status === 405) {
                        this.is_resend_confirmation_dialog_visible = true;
                        globals.is_login_dialog_visible = false;
                    } else if (response.status === 401) {
                        globals.show_error('errors.login_failed');
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("send_login failed", e);
                }
                finally {
                    globals.hide_loading();
                }
            },
            async handle_success_login() {
                if (globals.loggedIn) { // Angenommen, loginSuccess wird auf true gesetzt, wenn die Anmeldung erfolgreich ist
                    if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect);
                    } else if (this.$route.name != 'Login') {
                        globals.is_login_dialog_visible = false;
                    } else {
                        this.$router.push("/");
                    }
                }
            },
            async send_forgot_password() {

                const url = "authenticate/forgot-password";
                const data = { "email": this.username, "provider": this.globals.provider, "service": this.globals.service };
                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, data);
                    if (response.status === 200) {
                        this.is_forgot_password_dialog_visible = false;
                        this.is_success_forgot_passwort_dialog_visible = true;
                    } else if (response.status === 405) {
                        this.is_resend_confirmation_dialog_visible = true;
                        this.is_forgot_password_dialog_visible = false;
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },            
            async send_forgot_password_confirmation(confirm_forgotpw_token) {
                const url = "authenticate/forgot-password?code=" + confirm_forgotpw_token;
                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);
                    if (response.status === 200) {
                        var result = JSON.parse(response.responseText);
                        this.reset_password_token = result.payload.reset_password_token;
                        this.is_set_new_password_dialog_visible = true;                            
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async send_new_password() {
                if ((this.new_password + "") !== (this.confirm_new_password + "")) {
                    this.globals.show_error('errors.password_not_match');
                    return;
                }
                if (!this.$refs.checkpassword_component.checkPasswordStrength(this.new_password)) {
                    this.globals.show_error('errors.password_requirements_failed');
                    return;
                }
                const url = "authenticate/reset-password";
                const pw_hash = await security.hash_pw(this.new_password);
                const data = { "reset_password_token": this.reset_password_token, "new_password": pw_hash };
                try {
                    const response = await this.globals.make_Xhr_Request("PATCH", url, data);
                    if (response.status === 200) {
                        this.is_set_new_password_dialog_visible = false;
                        this.is_success_set_passwort_dialog_visible = true;
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async send_2fa_code() {
                const url = "authenticate/login-with-mfa";
                const data = { "access_token": this.access_token, "mfa_code": this.twofa_code };

                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, data);
                    if (response.status === 200) {
                        const payload = JSON.parse(response.responseText).payload;
                        await this.security.process_post_login(payload);
                        await this.handle_success_login();
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async send_2fa_recoverycode() {
                const url = "authenticate/login-with-recoverycode";
                const data = { "access_token": this.access_token, "recovery_code": this.twofa_code };

                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, data);
                    if (response.status === 200) {
                        const payload = JSON.parse(response.responseText).payload;
                        await this.security.process_post_login(payload);
                        await this.handle_success_login();
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async resend_confirmation() {
                this.is_resend_confirmation_dialog_visible = false;
                const url = "register/resend-email-confirmation";
                const data = { "email": this.username, "provider": this.globals.provider, "service": this.globals.service }
                

                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, data);
                    if (response.status === 200) {
                        this.is_success_resend_confirmation_dialog_visible = true;
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },            
            async send_change_email_confirmation(confirmation_token) {

                const url = "account/confirm-change-email?token=" + confirmation_token

                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);
                    if (response.status === 200) {
                        this.is_change_email_confirmation_success_dialog_visible = true;
                        this.security.removeTokensInLocalstorage();
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            show_forgot_password() {
                globals.is_login_dialog_visible = false;
                this.is_forgot_password_dialog_visible = true;
            },
            show_mfa_authentication(payload) {
                globals.is_login_dialog_visible = false;
                this.access_token = payload.access_token;
                if (payload.token_provider === "Authenticator") {
                    this.is_2fa_code_dialog_visible = 2;

                } else if (payload.token_provider === "EMail") {
                    this.is_2fa_code_dialog_visible = 1;
                }
            },            
            close_change_email_confirmation() {
                this.is_change_email_confirmation_success_dialog_visible = false;
                globals.goto_new_upload();
            },
            close_success_set_passwort_dialog() {
                this.is_success_set_passwort_dialog_visible = false;
                globals.goto_new_upload();
            },
            close_login_dialog() {
                globals.is_login_dialog_visible = false;
                this.is_2fa_code_dialog_visible = 0;

                
            },
            handleKeyDown(event) {
                //if (event.key === 'Escape') {
                //    if (globals.is_login_dialog_visible)
                //        globals.is_login_dialog_visible = false;
                //    else if (this.is_resend_confirmation_dialog_visible)
                //        this.is_resend_confirmation_dialog_visible = false;
                //    else if (this.is_success_resend_confirmation_dialog_visible)
                //        this.is_success_resend_confirmation_dialog_visible = false;
                //    else if (this.is_forgot_password_dialog_visible)
                //        this.is_forgot_password_dialog_visible = false;
                //    else if (this.is_success_forgot_passwort_dialog_visible)
                //        this.is_success_forgot_passwort_dialog_visible = false;
                //    else if (this.is_set_new_password_dialog_visible)
                //        this.is_set_new_password_dialog_visible = false;
                //    else if (this.is_success_set_passwort_dialog_visible) {
                //        this.close_success_set_passwort_dialog();
                //    }
                //    else if (this.is_change_email_confirmation_success_dialog_visible) {
                //        this.close_change_email_confirmation();
                //    }
                //}
            },
            async show_me() {
                globals.setPageTitel("login.headline");
                //console.log(this.$route.name);
                //console.log(this.confirm_forgotpw_token);
                if (this.$route.name === 'Login_forgot_pw') {
                    this.is_forgot_password_dialog_visible = true;

                } else if (this.$route.name === 'Login_new_pw') {

                } else if (this.$route.name === 'Login_resend_confirmation') {
                    this.is_resend_confirmation_dialog_visible = true;
                }

                else if (this.$route.name === 'Login_confirm_forgot_pw' && this.token) {
                    this.confirm_forgotpw_token = this.token;
                    console.log("confirm_forgotpw_token", this.confirm_forgotpw_token);
                    if (globals.loggedIn) {
                        //dann raus aus loing
                        this.$router.push('/');
                        return;
                    }
                    await this.send_forgot_password_confirmation(this.confirm_forgotpw_token);

                } else if (this.$route.name === 'Login_confirm_change_email' && this.token) {
                    this.confirm_token = this.token;
                } else if (this.$route.name === 'Login') {
                    globals.is_login_dialog_visible = true;
                    if (globals.loggedIn) {
                        //dann raus aus login
                        this.$router.push('/');
                    }
                } else if (this.$route.name === 'Logout') {

                    globals.setPageTitel("login.logout.headline");
                    await security.logout(true);
                    this.$router.push('/');
                }
                document.addEventListener('keydown', this.handleKeyDown);
            }
            
        },
        mounted: async function () {
            // Beim Laden der Seite prüfen, ob ein JWT-Token im Local Storage vorhanden ist
            globals.is_login_dialog_visible = false;
            this.is_resend_confirmation_dialog_visible = false;
            this.is_success_resend_confirmation_dialog_visible = false;
            await this.show_me();
            
            document.addEventListener('keydown', this.handleKeyDown);
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        },

    }
</script>