<template>
    <header>
        <div class="site-container header-content">
            <div id="small-logo" v-if="globals.is_white_label_branding_modus">
                <a v-if="globals.current_icon_link_url" :href="globals.current_icon_link_url">
                    <img v-if="globals.current_icon_image_url" alt="logo" style="width:44px;" :src="globals.current_icon_image_url" />
                    <img v-else alt="logo" style="width:44px;" src="../assets/images/logo.png" />
                </a>
                <a v-else :href="globals.get_home_url()">
                    <img v-if="globals.current_icon_image_url" alt="logo" style="width:44px;" :src="globals.current_icon_image_url" />
                    <img v-else alt="logo" style="width:44px;" src="../assets/images/logo.png" />
                </a>
            </div>
            <div id="small-logo" v-else>
                <a :href="globals.get_home_url()"> 
                    <img alt="logo" style="width:44px;" src="../assets/images/logo.png" />
                </a>
            </div>
            <div class="header-button" v-if="!globals.loggedIn && !globals.is_white_label_branding_modus">
                <a @click="globals.is_login_dialog_visible = true;"><span><i class="fa-solid fa-right-to-bracket"></i> {{ $t('menu.login')}}</span></a>
            </div>
            <div class="menu-button" v-if="globals.loggedIn" ref="MenuButton">
                <div @click="show_menu()"><i class="fa-solid fa-lg fa-bars"></i></div>
                <nav ref="MenuPanel" class="menu" :class="{ 'show': is_menu_panel_visible }">
                    <router-link @click="hide_menu()" :to="{ name: 'Upload' }">
                        <span class="menu_item"><i class="fa-solid fa-cloud-arrow-up"></i>{{$t('menu.upload')}}</span>
                    </router-link>
                    <router-link @click="hide_menu()" :to="{ name: 'History' }">
                        <span class="menu_item"><i class="fa-solid fa-clock-rotate-left"></i>{{$t('menu.history')}}</span>
                    </router-link>
                    <hr />
                    <router-link @click="hide_menu()" :to="{ name: 'SecureStringUpload' }">
                        <span class="menu_item"><i class="fa-solid fa-shield-keyhole"></i>{{$t('menu.secure_string')}}</span>
                    </router-link>
                    <hr />
                    <router-link @click="hide_menu()" :to="{ name: 'Profile' }">
                        <span class="menu_item"><i class="fa-solid fa-user"></i>{{$t('menu.account')}}</span>
                    </router-link>
                    <router-link @click="hide_menu()" :to="{ name: 'Logout' }">
                        <span class="menu_item"><i class="fa-solid fa-right-from-bracket"></i>{{$t('menu.logout')}}</span>
                    </router-link>
                </nav>
            </div>
        </div>
    </header>
</template>
<script>
    import globals from '../utils/Globals';
    import security from '../utils/Security';
    export default {
        name: 'SiteHeader',
        setup() {
            return {
                globals, security
            };
        },
        components: {
        },
        props: {
            
        },
        data: () => ({
            is_menu_panel_visible: false,
            current_username: security.current_username,
        }),
        methods: {
            show_login() {
                globals.is_login_dialog_visible = true;
            },
            show_menu() {
                this.current_username = security.current_username;
                this.is_menu_panel_visible = !this.is_menu_panel_visible;
            },
            hide_menu() {
                this.is_menu_panel_visible = false;
            },
            handleClickOutside(event) {
                if (this.$refs.MenuPanel && this.$refs.MenuButton) {
                    if (!this.$refs.MenuPanel.contains(event.target) && !this.$refs.MenuButton.contains(event.target)) {
                        this.hide_menu();
                    }
                }
            }
        },
        mounted: function () {
            //console.log("globals.loggedIn", globals.loggedIn);
            document.addEventListener("click", this.handleClickOutside);
            
        },
        beforeDestroy() {
            document.removeEventListener("click", this.handleClickOutside);
        }
    }
</script>