import { createRouter, createWebHistory } from 'vue-router';
import Register from '@common_views/Register.vue';
import Profile from '@common_views/Profile.vue';
import Login from '@common_views/Login.vue';
import Status from './views/Status.vue';
import Home from './views/Home.vue';

import security from '@common_utils/Security';
import globals from '@common_utils/Globals';


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        props: true
    },
    
    {
        path: '/register/:register_token?/:register_with_email?',
        name: 'Register',
        component: Register,
        props: true
    },
    {
        path: '/confirm-email',
        name: 'Register_confirm_email',
        component: Register,
        props: (route) => ({ token: route.query.token })
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Login
    },
    {
        path: '/forgot_pw',
        name: 'Login_forgot_pw',
        component: Login
    },
    {
        path: '/new_pw',
        name: 'Login_new_pw',
        component: Login
    },
    {
        path: '/resend_confirmation',
        name: 'Login_resend_confirmation',
        component: Login
    },
    {
        path: '/confirm-forgot-pw',
        name: 'Login_confirm_forgot_pw',
        component: Login,
        props: (route) => ({ token: route.query.token })
    },
    {
        path: '/confirm-change-email',
        name: 'Login_confirm_change_email',
        component: Login,
        props: (route) => ({ token: route.query.token }),
    },
    {
        path: '/status/:project_id?/:task_ids?',
        name: 'Status',
        component: Status,
        props: true
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true }
    },
    {
        path: '/init-change-email',
        name: 'Profile_init_change_email',
        component: Profile,
        props: (route) => ({ token: route.query.token }),
        meta: { requiresAuth: true }
    },
    //{
    //    path: '/privacy',
    //    name: 'PrivacyPage',
    //    component: PrivacyPage,
    //},
    // Fallback-Route
    {
        path: '/:pathMatch(.*)*', // F�r Vue Router 4 (Vue 3)
        name: 'Fallback',
        beforeEnter: (to, from, next) => {
            // Umleiten zur Startseite
            next('/');
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
// Globaler Navigation Guard
router.beforeEach((to, from, next) => {
    // Subdomain-Umleitung
    console.log("Hallo");
    console.log("to", to);
    
    console.log("window.location", window.location)
    const currentHostname = window.location.hostname;

    if ((currentHostname === 'localhost' || currentHostname === 'status.file.direct') && (!to.params.project_id || !to.params.task_id)) {
        // Setze die vorgegebenen Parameter f�r die Status-Komponente, wenn sie noch nicht gesetzt sind
        const params = {
            project_id: '3e366899-625e-45e8-ba49-8885674ac930',
            task_ids: ['06a6ef9e-024c-42ee-82f1-5042fde53fa8', 'a8d62a08-c20c-410e-817a-d55fe8d0a1b9'],
            goto: 'status'
        };
        globals.is_white_label_branding_modus = true;

        // Setze die Parameter direkt und navigiere ohne die URL zu �ndern
        to.params = { ...params, ...to.params };
        next(); // Setze die Route ohne URL zu �ndern
        return;
    }


    if (currentHostname.startsWith('app.') ||
        currentHostname.startsWith('web.')
    ) {
        const home_url = globals.get_home_url();
        window.location.href = new URL(to.fullPath, home_url).toString();;
        return;
    }
    
    if (to.name === 'logout') {
        next();
        return;
    }

    security.refresh_jwt().then(() => {
        // JWT wurde erfolgreich aktualisiert, fahren Sie mit der Navigation fort
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        const isLoggedIn = globals.loggedIn; 

        if (requiresAuth && !isLoggedIn) {
            next({
                path: '/login',
                query: { redirect: to.fullPath } // Weitergabe der urspr�nglichen Route als Query-Parameter
            });
        } else {
            next(); // Bei Erf�llung der Bedingung oder keiner Notwendigkeit der Authentifizierung
        }
    }).catch((error) => {
        console.error("Fehler beim JWT-Refresh:", error);
        next('/');
    });
});

export default router;